export enum EnumAnalyticCandidateProperty {
  SIGN_UP_METHOD = 'sign up method',
  SIGN_UP_DATE = 'sign up date',

  SELECTED_SKILLS = 'selected skills',
  PROVED_QUIZ_SKILLS = 'proved skills quiz',
  PROVED_CODING_SKILLS = 'proved skills coding',
  EXPERIENCE = 'experience',
  JOINED_FROM = 'joined from',

  EMAIL = 'email',

  DEVICE_TYPE = 'device type',
}

export enum EnumAnalyticCompanyProperty {
  SIGN_UP_METHOD = 'sign up method',
  SIGN_UP_DATE = 'sign up date',

  EMAIL = 'email',
  DEVICE_TYPE = 'device type',
}
