import React from 'react'

import clsx from 'clsx'

import {
  Button,
  ButtonAnchor,
  EnumButtonVariants,
  Tooltip,
  EnumBreakpoints,
  useMediaQuery,
} from '@insquad/tools'

import { Maybe, SkillStepType, VettedSkillStep } from 'graphql/types'
import { skillTypeConverter } from 'utils/skillTypeConverter'

import { ChallengeInfo } from '../ChallengeInfo/ChallengeInfo'

import s from './CardContent.module.scss'

export interface ICardContentProps {
  isOpen: boolean
  steps: VettedSkillStep[]
  isButtonsDisabled: boolean | undefined
  disabledButtonsTooltipContent: string | undefined

  onClickWatchResult?: (stepType: SkillStepType) => void
}

export const CardContent: React.FC<ICardContentProps> = ({
  isOpen,
  steps,
  isButtonsDisabled,
  disabledButtonsTooltipContent,
  onClickWatchResult,
}) => {
  const isMobile = useMediaQuery(EnumBreakpoints.BREAKPOINT_SM, 'min')
  const isOneStep = steps.length === 1
  const handleWatchInterview = (stepType: SkillStepType) => {
    return () => onClickWatchResult?.(stepType)
  }

  const refCallback = (node: HTMLDivElement | null) => {
    if (node) {
      if (isOpen) {
        node.style.maxHeight = isMobile
          ? (node.scrollHeight || 0) + 'px'
          : (node.scrollHeight * 2 || 0) + 'px'
      } else {
        node.style.maxHeight = '0px'
      }
    }
  }

  const renderButton = (
    stepType: SkillStepType,
    text: string,
    url?: Maybe<string> | undefined,
    isOneStep?: boolean
  ) => {
    if (!url) {
      return null
    }

    if (isButtonsDisabled && disabledButtonsTooltipContent) {
      return (
        <Tooltip
          usePopperConfig={{ placement: 'top', offset: [0, 26] }}
          tooltipContent="All functions will be available after the demo call"
          contentMaxWidth={378}
        >
          {({ setTriggerRef }) => (
            <div ref={setTriggerRef}>
              <Button
                variant={EnumButtonVariants.SECONDARY}
                className={clsx(s.CardContent__button, {
                  [s.CardContent__button_optinal]: isOneStep,
                })}
                disabled
              >
                {text}
              </Button>
            </div>
          )}
        </Tooltip>
      )
    }

    if (isButtonsDisabled) {
      return (
        <Button
          variant={EnumButtonVariants.SECONDARY}
          className={clsx(s.CardContent__button, {
            [s.CardContent__button_optinal]: isOneStep,
          })}
          disabled
        >
          {text}
        </Button>
      )
    }

    return (
      <ButtonAnchor
        variant={EnumButtonVariants.SECONDARY}
        className={clsx(s.CardContent__button, {
          [s.CardContent__button_optinal]: isOneStep,
        })}
        href={url}
        target="_blank"
        onClick={handleWatchInterview(stepType)}
      >
        {text}
      </ButtonAnchor>
    )
  }

  return (
    <div ref={refCallback} className={s.CardContent}>
      <div className={s.CardContent__inner}>
        {steps.map((step, i) => {
          const isCoding = step.stepType === SkillStepType.Coding

          const buttonAnchorText = `See ${
            isCoding ? 'Code' : skillTypeConverter[step.stepType] + '  solution'
          }`

          return (
            <div key={i} className={s.CardContent__step}>
              <ChallengeInfo
                title={skillTypeConverter[step.stepType]}
                step={step}
                isOneStep={isOneStep}
                isOpen={isOpen}
              >
                {renderButton(
                  step.stepType,
                  buttonAnchorText,
                  step.reportUrl,
                  isOneStep
                )}
              </ChallengeInfo>
            </div>
          )
        })}
      </div>
    </div>
  )
}
