import { gql } from '@apollo/client'

import { FRAGMENT_RESUME_FULL_WITH_PHONE } from 'graphql/fragments/resumeFragment'
import { Resume } from 'graphql/types'

export interface IQueryGetResumeWithPhoneResponse {
  result: Resume
}

export interface IQueryGetResumeWithPhoneVars {
  sharedByCandidate: true
  id: string
}

export const QUERY_GET_RESUME_WITH_PHONE = gql`
  query getResume($id: ID!, $sharedByCandidate: Boolean) {
    result: getResume(id: $id, sharedByCandidate: $sharedByCandidate) {
      ...FragmentResumeFullWithPhone
    }
  }

  ${FRAGMENT_RESUME_FULL_WITH_PHONE}
`
