import React from 'react'

import { TextButton } from './TextButton'
import { EnumTextButtonVariants, ITextButtonProps } from './types'

export const TextButtonAnchor = (props: Omit<ITextButtonProps<'a'>, 'as'>) => {
  return <TextButton as="a" {...props} />
}

TextButtonAnchor.Variant = EnumTextButtonVariants
