import { gql } from '@apollo/client'

export const FRAGMENT_LOGIN_FULL = gql`
  fragment FragmentLoginFull on Login {
    id
    userType
    username
    firstName
    lastName
    email
    phone
    city
    country
    countryCode
    locale
    photo
    timezone
    externalId
    createdAt
    updatedAt
    joinedFrom
  }
`
