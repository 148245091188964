import React from 'react'
import { useLocation } from 'react-router-dom'

import { useUpdateEffect } from '@insquad/tools'

export interface IScrollTopOnRouteChangeProps {}

export const ScrollTopOnRouteChange: React.FC<
  IScrollTopOnRouteChangeProps
> = () => {
  const { pathname } = useLocation()

  useUpdateEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
