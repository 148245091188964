import { WebSocketLink } from '@apollo/client/link/ws'

import { REACT_APP_BACKEND_WSS } from 'utils/config'
import { getAuth0Token } from 'utils/getAuth0Token'

export const wsLink = new WebSocketLink({
  uri: `${REACT_APP_BACKEND_WSS}/subscriptions`,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: `Bearer ${getAuth0Token()}`,
    },
  },
})
