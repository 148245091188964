function env<T = string>(variable: string | undefined, defaultValue: T) {
  if (typeof variable === 'undefined') {
    return defaultValue
  }
  return variable
}

function requireEnv(variable: string | undefined, name: string) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`)
  }
  return variable
}

export const REACT_APP_BACKEND_API = requireEnv(
  process.env.REACT_APP_BACKEND_API,
  'REACT_APP_BACKEND_API'
)
export const REACT_APP_BACKEND_WSS = requireEnv(
  process.env.REACT_APP_BACKEND_WSS,
  'REACT_APP_BACKEND_WSS'
)
export const REACT_APP_AUTH0_DOMAIN = requireEnv(
  process.env.REACT_APP_AUTH0_DOMAIN,
  'REACT_APP_AUTH0_DOMAIN'
)
export const REACT_APP_AUTH0_CLIENT_ID = requireEnv(
  process.env.REACT_APP_AUTH0_CLIENT_ID,
  'REACT_APP_AUTH0_CLIENT_ID'
)
export const REACT_APP_AUTH0_AUDIENCE = requireEnv(
  process.env.REACT_APP_AUTH0_AUDIENCE,
  'REACT_APP_AUTH0_AUDIENCE'
)

export const REACT_APP_ANALYTIC_KEY = env(
  process.env.REACT_APP_ANALYTIC_KEY,
  ''
)
export const REACT_APP_EXAMPLE_RESUME_ID = env(
  process.env.REACT_APP_EXAMPLE_RESUME_ID,
  '1524a655-33ff-46c5-96b6-fa217ab464bb'
)
export const REACT_APP_GTM = env(process.env.REACT_APP_GTM, '')
export const REACT_APP_GTM_COMPANY = env(process.env.REACT_APP_GTM_COMPANY, '')
export const REACT_APP_ANALYTIC_COMPANY_KEY = env(
  process.env.REACT_APP_ANALYTIC_COMPANY_KEY,
  ''
)
export const REACT_APP_HOTJAR_HJID = env(process.env.REACT_APP_HOTJAR_HJID, '0')
export const REACT_APP_HOTJAR_HJSV = env(process.env.REACT_APP_HOTJAR_HJSV, '0')
export const REACT_APP_SENTRY_DSN = env(process.env.REACT_APP_SENTRY_DSN, '')
export const REACT_APP_RESULT_SHARE_PREVIEW_URL = env(
  process.env.REACT_APP_RESULT_SHARE_PREVIEW_URL,
  ''
)
export const REACT_APP_RESUME_SHARE_PREVIEW_URL = env(
  process.env.REACT_APP_RESUME_SHARE_PREVIEW_URL,
  ''
)
