import React, { useRef, useState } from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography, useMount } from '@insquad/tools'

import { Maybe } from 'graphql/types'

import { ReactComponent as VettedSkillIcon } from './vettedSkillIcon.svg'

import s from './TechnologyInfo.module.scss'

export interface ITechnologyInfoProps {
  title: string
  cards: Maybe<
    {
      publicName: string
      icon?: Maybe<string> | undefined
      experience?: Maybe<number> | undefined
    }[]
  >
  isVetted?: boolean
  className?: string
  selectedFilters?: string[]
}

export const TechnologyInfo: React.FC<ITechnologyInfoProps> = ({
  title,
  cards,
  isVetted,
  className,
  selectedFilters,
}) => {
  const handlerClick = (name: string) => {
    const nameId = document.getElementById(name)
    nameId?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const [isAnimated, setIsAnimated] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  useMount(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsAnimated(true)
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return setIsAnimated(false)
  })

  if (!cards?.length) {
    return null
  }

  return (
    <div className={clsx(className)}>
      {isVetted ? (
        <>
          {title && (
            <Typography
              text={title}
              variant={EnumTypographyVariants.P2}
              className={s.TechnologyInfo__title}
            />
          )}

          <div className={s.TechnologyInfo__content}>
            {cards.map((c, i) => (
              <div
                className={s.TechnologyInfo__link}
                key={i}
                onClick={() => handlerClick(c.publicName)}
                ref={ref}
              >
                <Typography
                  className={clsx(s.TechnologyInfo__card, {
                    [s.TechnologyInfo__card_animation]: isAnimated,
                    [s.TechnologyInfo__card_selected]:
                      selectedFilters?.includes(
                        c.publicName.toLocaleLowerCase()
                      ),
                  })}
                  variant={EnumTypographyVariants.P2}
                  as="div"
                >
                  {c?.icon && (
                    <img
                      src={c.icon}
                      className={s.TechnologyInfo__img}
                      alt=""
                    />
                  )}

                  <Typography
                    variant={EnumTypographyVariants.P2}
                    className={s.TechnologyInfo__name}
                    text={c?.publicName?.replace(' + Python', '') || ''}
                  />

                  <div className={s.TechnologyInfo__icn}>
                    <VettedSkillIcon />
                  </div>

                  <Typography
                    variant={EnumTypographyVariants.P2}
                    className={s.TechnologyInfo__experience}
                    text={`${c.experience || 1} ${
                      c.experience || 1 > 1 ? 'yrs' : 'yr'
                    }`}
                  />
                </Typography>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          {title && (
            <Typography
              text={title}
              variant={EnumTypographyVariants.P2}
              className={s.TechnologyInfo__title}
            />
          )}

          <div className={s.TechnologyInfo__content}>
            {cards.map((c, i) => (
              <div
                key={i}
                className={clsx(s.TechnologyInfo__card, {
                  [s.TechnologyInfo__card_selected]: selectedFilters?.includes(
                    c.publicName.toLocaleLowerCase()
                  ),
                })}
              >
                {c?.icon && (
                  <img src={c.icon} className={s.TechnologyInfo__img} alt="" />
                )}

                <Typography
                  variant={EnumTypographyVariants.P2}
                  className={s.TechnologyInfo__name}
                  text={c?.publicName || ''}
                />

                {!!c?.experience && (
                  <Typography
                    variant={EnumTypographyVariants.P2}
                    className={s.TechnologyInfo__experience}
                    text={`${c.experience} ${c.experience > 1 ? 'yrs' : 'yr'}`}
                  />
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
