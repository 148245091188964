import { RouteObject } from 'react-router-dom'

import { EnumPublicRoutes } from 'common/enums/routesEnums'
import { NotFoundLayout } from 'common/layouts/NotFoundLayout/NotFoundLayout'

import { CandidateResumePage } from './CandidateResumePage/CandidateResumePage'
import { CandidateShareResultPage } from './CandidateShareResultPage/CandidateShareResultPage'
import { CandidateShareResumePage } from './CandidateShareResumePage/CandidateShareResumePage'

export const PUBLIC_ROUTES: RouteObject[] = [
  {
    path: EnumPublicRoutes.CANDIDATE_RESUME_ROUTE,
    element: <CandidateResumePage />,
  },
  {
    path: EnumPublicRoutes.CANDIDATE_SHARE_PROFILE_ROUTE,
    element: <CandidateShareResumePage />,
  },
  {
    path: EnumPublicRoutes.CANDIDATE_SHARE_RESULT_ROUTE,
    element: <CandidateShareResultPage />,
  },
  {
    path: '*',
    element: <NotFoundLayout />,
  },
]
