import { gql } from '@apollo/client'

import { FRAGMENT_SALES_OFFER } from 'graphql/fragments/salesOfferFragment'
import { SalesOffer } from 'graphql/types'

export interface IGetSalesOfferResponse {
  result: SalesOffer
}

export interface IQueryGetSalesOfferVars {
  slug: string
}

export const QUERY_GET_SALES_OFFER = gql`
  query getSalesOffer($slug: String!) {
    result: getSalesOffer(slug: $slug) {
      ...FragmentSalesOffer
    }
  }

  ${FRAGMENT_SALES_OFFER}
`
