export interface Time {
  hh: string
  mm: string
  ss: string
}

export function millisecondsToTime(ms: number, offset = 0): Time {
  const roundedSeconds = Math.round(ms / 1000 + offset)

  const hours: number = Math.floor(roundedSeconds / 3600)
  const diversForMinutes: number = roundedSeconds % 3600
  const minutes: number = Math.floor(diversForMinutes / 60)
  const sec: number = Math.ceil(diversForMinutes % 60)

  return {
    hh: hours.toString(),
    mm: minutes < 10 ? `0${minutes}` : minutes.toString(),
    ss: sec < 10 ? `0${sec}` : sec.toString(),
  }
}
