import { gql } from '@apollo/client'

import { FRAGMENT_CANDIDATE_FULL } from 'graphql/fragments/candidateFragment'
import { Candidate } from 'graphql/types'

export interface IGetCandidateResponse {
  result: Candidate
}

export const QUERY_GET_CANDIDATE = gql`
  query getCandidate {
    result: getCandidate {
      ...FragmentCandidateFull
    }
  }

  ${FRAGMENT_CANDIDATE_FULL}
`
