import dayjs from 'dayjs'

import {
  ResumeLanguage,
  Maybe,
  ResumeTechnology,
  Work,
  VettedSkill,
} from 'graphql/types'

const getDatesDifference = (startTime: string, endTime?: Maybe<string>) => {
  const start = dayjs(startTime)
  const end = dayjs(endTime || dayjs())

  const yearsDuration = end.diff(start, 'years')
  const monthsDuration = end.diff(start, 'months') - yearsDuration * 12
  const daysDuration =
    yearsDuration <= 0 && monthsDuration <= 0 ? end.diff(start, 'days') : 0

  return {
    yearsDuration,
    monthsDuration,
    daysDuration,
  }
}

export const getDurationString = (
  startTime?: Maybe<string>,
  endTime?: Maybe<string>
): Maybe<string> => {
  if (!startTime) {
    return null
  }

  const { yearsDuration, monthsDuration, daysDuration } = getDatesDifference(
    startTime,
    endTime
  )

  let output = ''

  if (yearsDuration > 0) {
    output += `${yearsDuration} year${yearsDuration > 1 ? 's' : ''}`
  }

  if (monthsDuration > 0) {
    output += `${yearsDuration > 0 ? ' ' : ''}${monthsDuration} month${
      monthsDuration > 1 ? 's' : ''
    }`
  }

  if (daysDuration > 0) {
    output += `${monthsDuration > 0 ? ' ' : ''}${daysDuration} day${
      daysDuration > 1 ? 's' : ''
    }`
  }

  return output
}

export const getDurationPeriodsString = (
  startTime?: Maybe<string>,
  endTime?: Maybe<string>
): Maybe<string> => {
  if (!startTime) {
    return null
  }

  const start = dayjs(startTime).format('MMMM YYYY')
  const end = endTime ? dayjs(endTime).format('MMMM YYYY') : 'Until now'

  return `${start} - ${end}`
}

interface IGetHasExperienceInProps {
  languages: Maybe<Array<ResumeLanguage>>
  technologies: Maybe<Array<ResumeTechnology>>
  workArray: Maybe<Array<Work>>
  vettedSkills: Maybe<VettedSkill[]>
}

export const getHasExperienceIn = ({
  languages,
  technologies,
  workArray,
  vettedSkills,
}: IGetHasExperienceInProps): string[] => {
  const experienceNamesSet: Set<string> = new Set()

  for (const work of workArray || []) {
    work.Languages?.forEach(({ publicName }) =>
      experienceNamesSet.add(publicName)
    )
    work.Technologies?.forEach(({ publicName }) =>
      experienceNamesSet.add(publicName)
    )
  }

  const getExcludeObject = (items: { publicName: string }[]) => {
    return items?.reduce((accom, { publicName }) => {
      return {
        ...accom,
        [publicName]: true,
      }
    }, {} as Record<string, boolean>)
  }

  const languagesToExclude = getExcludeObject(languages ?? [])
  const technologiesToExclude = getExcludeObject(technologies ?? [])
  const vettedSkillsToExclude = getExcludeObject(vettedSkills ?? [])

  return Array.from(experienceNamesSet).filter(
    (name) =>
      !languagesToExclude[name] &&
      !technologiesToExclude[name] &&
      !vettedSkillsToExclude[name]
  )
}

const isValidUrl = (value: string) => {
  if (
    !value.match(
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
    )
  ) {
    return false
  }

  try {
    new URL(value)
    return true
  } catch {
    return false
  }
}

export const getHTMLFromResumeText = (text: string) => {
  const words = text.split(' ')

  for (let i = 0; i < words.length; i++) {
    const word = words[i]

    if (isValidUrl(word)) {
      words[i] = `<a href="${word}" target="_blank">${word}</a>`
    }
  }

  return words.join(' ')
}
