import React from 'react'

import clsx from 'clsx'

import { Avatar, IAvatarProps } from '../Avatar/Avatar'

import s from './AvatarWithName.module.scss'

export interface IAvatarWithNameProps extends IAvatarProps {
  firstNameLine?: string
  secondNameLine?: string
  wrapperClassName?: string
}

export const AvatarWithName: React.FC<IAvatarWithNameProps> = ({
  wrapperClassName,
  firstNameLine,
  secondNameLine,
  ...otherProps
}) => {
  return (
    <div className={clsx(s.AvatarWithName, wrapperClassName)}>
      <Avatar
        {...otherProps}
        className={clsx(
          { [s.AvatarWithName__avatarMargin]: firstNameLine || secondNameLine },
          otherProps.className
        )}
      />

      {(firstNameLine || secondNameLine) && (
        <div>
          {firstNameLine && (
            <div className={s.AvatarWithName__firstLine}>{firstNameLine}</div>
          )}

          {secondNameLine && (
            <div className={s.AvatarWithName__secondLine}>{secondNameLine}</div>
          )}
        </div>
      )}
    </div>
  )
}
