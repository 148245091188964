import { Spinner } from '@insquad/tools'

import { useSalesOfferInfo } from 'graphql/queries/landing/hooks/useSalesOfferQuery'

import SalesLandingContent from './SalesLandingContent/SalesLandingContent'
import SalesLandingHeader from './SalesLandingHeader/SalesLandingHeader'

import s from './SalesLandingPage.module.scss'

interface ISalesLandingPage {
  slug: string
}

export const SalesLandingPage: React.FC<ISalesLandingPage> = ({ slug }) => {
  const { companyName, technology, ShortResumes, isLoading } =
    useSalesOfferInfo(slug)

  return (
    <>
      {!isLoading ? (
        <>
          <SalesLandingHeader title={companyName} />
          <SalesLandingContent technology={technology} resumes={ShortResumes} />
        </>
      ) : (
        <div className={s.SalesLandingPage__spinner}>
          <Spinner size="xl" />
        </div>
      )}
    </>
  )
}
