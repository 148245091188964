import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { EnumTypographyVariants, Typography, Button } from '@insquad/tools'

import { BannerAnimation } from 'common/animations/BannerAnimation/BannerAnimation'
import { EnumCompanyQueries } from 'common/enums/companyQueriesEnum'
import { UserType } from 'graphql/types'

import { ReactComponent as LoginBannerIcon } from './loginBannerIcon.svg'

import s from './ResumeLoginBanner.module.scss'

export interface IResumeLoginBannerProps {
  resumeId: string
}

export const ResumeLoginBanner: React.FC<IResumeLoginBannerProps> = ({
  resumeId,
}) => {
  const { loginWithRedirect } = useAuth0()

  const handleSignUp = () => {
    loginWithRedirect({
      redirectUri:
        window.origin +
        `?${
          EnumCompanyQueries.ROUTE_QUERY_RESUME_ID
        }=${resumeId}&role=${UserType.Company.toLocaleLowerCase()}`,
    })
  }

  return (
    <BannerAnimation
      isVisible
      rootProps={{
        className: s.ResumeLoginBanner,
      }}
    >
      <div className={s.ResumeLoginBanner__content}>
        <Typography
          variant={EnumTypographyVariants.H3}
          text="Would you like to schedule an interview with this candidate?"
        />

        <Typography
          variant={EnumTypographyVariants.P1}
          mobileVariant={EnumTypographyVariants.P3}
          text="Register in our service and hire qualified developers from all over the world!"
          className={s.ResumeLoginBanner__description}
        />

        <Button onClick={handleSignUp} className={s.ResumeLoginBanner__btn}>
          Sign up
        </Button>
      </div>

      <div className={s.ResumeLoginBanner__icon}>
        <LoginBannerIcon />
      </div>
    </BannerAnimation>
  )
}
