import React, { useMemo } from 'react'

import clsx from 'clsx'

import { ReactComponent as ToolIcon } from 'assets/icons/simple/toolIcon.svg'
import {
  ResumeLanguage,
  Maybe,
  ResumeTechnology,
  VettedSkill,
} from 'graphql/types'
import { useCompanyStoreSkillsFilters } from 'routes/private/company/pages/CompanyCandidatesPage/_store/useCompanyStoreSkillsFilters/useCompanyStoreSkillsFilters'

import { CandidateResumeSection } from '../CandidateResumeSection/CandidateResumeSection'

import { TechnologyInfo } from './TechnologyInfo/TechnologyInfo'
import { TechnologyTitle } from './TechnologyTitle/TechnologyTitle'

import s from './CandidateResumeTechnologies.module.scss'

export interface ICandidateResumeTechnologiesProps {
  languages: Maybe<Array<ResumeLanguage>> | undefined
  technologies: Maybe<Array<ResumeTechnology>> | undefined
  vettedSkills: Maybe<VettedSkill[]> | undefined
}

export const CandidateResumeTechnologies: React.FC<
  ICandidateResumeTechnologiesProps
> = ({ languages = [], technologies = [], vettedSkills = [] }) => {
  const vettedMap = useMemo(() => {
    return (vettedSkills || []).reduce((accom, { name: id }) => {
      return {
        ...accom,
        [id]: true,
      }
    }, {} as Record<string, boolean>)
  }, [vettedSkills])

  const { languageIds, technologyIds } = useCompanyStoreSkillsFilters()

  const otherSkills = useMemo(() => {
    const filteredLanguages = languages?.filter(({ id }) => !vettedMap[id])
    const filteredTechnologies = technologies?.filter(
      ({ id }) => !vettedMap[id]
    )

    return (
      filteredLanguages?.concat(
        (filteredTechnologies as ResumeLanguage[]) || []
      ) || []
    )
  }, [languages, technologies, vettedMap])

  const vettedCards = useMemo(() => {
    const vettedSkillsWithExp = vettedSkills?.map(({ name, ...skill }) => {
      const foundEntity =
        languages?.find(({ id }) => id === name) ||
        technologies?.find(({ id }) => id === name)

      if (foundEntity) {
        return {
          ...skill,
          name,
          experience: foundEntity.experience,
        }
      }

      return {
        name,
        ...skill,
      }
    })

    return vettedSkillsWithExp
  }, [languages, technologies, vettedSkills])

  if (!languages?.length && !technologies?.length && !vettedSkills?.length) {
    return null
  }

  const selectedFilters = [...languageIds, ...technologyIds]

  return (
    <CandidateResumeSection
      title="Current stack"
      icon={<ToolIcon />}
      isBorderHidden
    >
      <article className={s.CandidateResumeTechnologies__wrapper}>
        {!!vettedCards?.length && (
          <>
            <TechnologyTitle title="Vetted skills" />

            <div className={s.CandidateResumeTechnologies__infoWrapper}>
              <TechnologyInfo
                title="This developer passed 3+ hours of rigorous tests for the following skills:"
                cards={vettedCards}
                isVetted
                className={s.CandidateResumeTechnologies__info}
                selectedFilters={selectedFilters}
              />
            </div>
          </>
        )}

        {!!otherSkills.length && (
          <>
            <TechnologyTitle
              className={clsx(
                s.CandidateResumeTechnologies__title,
                !!vettedSkills?.length &&
                  s.CandidateResumeTechnologies__titleMargin
              )}
              title={!vettedSkills?.length ? 'Skills' : 'Other skills'}
            />

            <div className={s.CandidateResumeTechnologies__infoWrapper}>
              <TechnologyInfo
                title=""
                cards={otherSkills}
                className={s.CandidateResumeTechnologies__info}
                selectedFilters={selectedFilters}
              />
            </div>
          </>
        )}
      </article>
    </CandidateResumeSection>
  )
}
