import { gql } from '@apollo/client'

export const FRAGMENT_VETTED_SKILL_STEP = gql`
  fragment FragmentVettedSkillStep on VettedSkillStep {
    skillName
    stepNumber
    versionNumber
    passed
    url
    status
    templateId
    scorePercent
    averageTime
    duration
    netTime
    stepType
    reportUrl
    tasks {
      score
      maxScore
      tags {
        name
      }
    }
  }
`

export const FRAGMENT_VETTED_SKILL = gql`
  fragment FragmentVettedSkill on VettedSkill {
    name
    publicName
    icon
    score
    steps {
      ...FragmentVettedSkillStep
    }
  }

  ${FRAGMENT_VETTED_SKILL_STEP}
`
