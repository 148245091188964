import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
  EnumBreakpoints,
  useMediaQuery,
} from '@insquad/tools'

import { ReactComponent as BookOpenIcon } from 'assets/icons/simple/bookOpenIcon.svg'
import { ReactComponent as BriefCaseIcon } from 'assets/icons/simple/briefcaseIcon.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/simple/checkIcon.svg'
import { ReactComponent as DollarIcon } from 'assets/icons/simple/dollarIcon.svg'
import { ReactComponent as GitHubIcon } from 'assets/icons/simple/githubIcon.svg'
import { ReactComponent as LocationIcon } from 'assets/icons/simple/locationIcon.svg'
import { ReactComponent as ResumeIcon } from 'assets/icons/simple/resumeIcon.svg'
import { ImgPreview } from 'common/components/ImgPreview/ImgPreview'
import { Maybe } from 'graphql/types'

import s from './CandidateResumeInfoFull.module.scss'

interface IGetListItemsProps
  extends Pick<
    ICandidateResumeInfoFullProps,
    | 'experience'
    | 'overlap'
    | 'price'
    | 'isLocationHidden'
    | 'isSalaryHidden'
    | 'englishLevel'
    | 'githubLink'
  > {
  salaryClassName: string | undefined
}

const getEngLevel = (englishLevel: string) => {
  switch (englishLevel) {
    case 'B2':
      return 'Intermediate'
    case 'C1':
      return 'Advanced'
    case 'C2':
      return 'Proficient'
  }
  return ''
}

const getListItems = ({
  price,
  experience,
  overlap,
  englishLevel,
  githubLink,
  isLocationHidden,
  isSalaryHidden,
  salaryClassName,
}: IGetListItemsProps) => {
  const output = [
    experience && {
      text: experience,
      icon: <BriefCaseIcon />,
    },
    !isLocationHidden &&
      overlap && {
        text: overlap,
        icon: <LocationIcon />,
      },
    {
      text: 'Availability: Ready to start in 2 weeks',
      icon: <CheckIcon />,
    },
    {
      text: 'Full time - 40 hours per week',
      icon: <ResumeIcon />,
    },
    englishLevel && {
      text: `English level: ${getEngLevel(englishLevel)}`,
      icon: <BookOpenIcon />,
    },
    githubLink && {
      text: `GitHub:`,
      icon: <GitHubIcon />,
      link: `${githubLink}`,
    },
    !isSalaryHidden &&
      price && {
        text: `Developer price range: ${price}`,
        icon: <DollarIcon />,
        className: salaryClassName,
      },
  ].filter(Boolean)

  return output as Array<{
    text: string
    icon: JSX.Element
    className?: string
    link?: string
  }>
}

export interface ICandidateResumeInfoFullProps {
  firstName: Maybe<string> | undefined
  lastName: Maybe<string> | undefined
  photo: Maybe<string> | undefined
  specialization: Maybe<string> | undefined
  experience: Maybe<string> | undefined
  overlap: Maybe<string> | undefined
  price: Maybe<string> | undefined
  englishLevel: Maybe<string> | undefined
  githubLink: Maybe<string> | undefined
  actions?: React.ReactNode | (() => React.ReactNode)
  isLocationHidden?: boolean
  isSalaryHidden?: boolean
  imgClassName?: string
  nameClassName?: string

  salaryClassName?: string
}

export const CandidateResumeInfoFull: React.FC<
  ICandidateResumeInfoFullProps
> = ({
  experience,
  firstName,
  lastName,
  photo,
  specialization,
  overlap,
  actions,
  price,
  englishLevel,
  githubLink,
  isLocationHidden,
  isSalaryHidden,
  nameClassName,
  salaryClassName,
}) => {
  const isDesktop = useMediaQuery(EnumBreakpoints.BREAKPOINT_MD, 'min')

  const renderActions = () => {
    if (!actions) {
      return null
    }

    return (
      <div className={s.CandidateResumeInfoFull__actions}>
        {typeof actions === 'function' ? actions() : actions}
      </div>
    )
  }

  return (
    <section className={s.CandidateResumeInfoFull}>
      {isDesktop && (
        <ImgPreview
          imageSrc={photo}
          className={clsx(s.CandidateResumeInfoFull__avatar)}
          classes={{ image: s.CandidateResumeInfoFull__avatar_img }}
        />
      )}

      <div className={s.CandidateResumeInfoFull__content}>
        <div className={s.CandidateResumeInfoFull__header}>
          {!isDesktop && (
            <ImgPreview
              imageSrc={photo}
              className={clsx(s.CandidateResumeInfoFull__avatar)}
            />
          )}

          <div className={s.CandidateResumeInfoFull__headerInner}>
            <div className={s.CandidateResumeInfoFull__titleWrapper}>
              <Typography
                text={(firstName || '') + ' ' + (lastName || '')}
                variant={EnumTypographyVariants.H2}
                className={clsx(
                  s.CandidateResumeInfoFull__title,
                  nameClassName
                )}
              />

              {isDesktop && renderActions()}
            </div>

            {!!specialization && (
              <Typography
                variant={EnumTypographyVariants.P2}
                text={specialization}
                as="div"
              />
            )}
          </div>
        </div>

        <ul className={s.CandidateResumeInfoFull__list}>
          {getListItems({
            experience,
            overlap,
            price,
            englishLevel,
            githubLink,
            isLocationHidden,
            isSalaryHidden,
            salaryClassName,
          }).map(({ text, icon, link, className }, i) => (
            <Typography
              key={i}
              variant={EnumTypographyVariants.P2}
              className={clsx(s.CandidateResumeInfoFull__listItem, className)}
              as="li"
            >
              <div className={s.CandidateResumeInfoFull__icon}>{icon}</div>
              {text}
              {link && (
                <a className={s.CandidateResumeInfoFull__link} href={link}>
                  {link}
                </a>
              )}
            </Typography>
          ))}
        </ul>

        {!isDesktop && renderActions()}
      </div>
    </section>
  )
}
