import React from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography, Tag } from '@insquad/tools'

import { ReactComponent as BriefCaseIcon } from 'assets/icons/simple/briefcaseIcon.svg'
import { ReactComponent as ClockIcon } from 'assets/icons/simple/clockIcon.svg'
import { Maybe, Work } from 'graphql/types'

import {
  getDurationPeriodsString,
  getDurationString,
  getHTMLFromResumeText,
} from '../../_utils'

import s from './WorkExperienceCard.module.scss'

export interface IWorkExperienceCardProps extends Work {
  selectedFilters: string[]
}

export const WorkExperienceCard: React.FC<IWorkExperienceCardProps> = ({
  Languages,
  Technologies,
  Domains,
  companyName,
  description,
  position,
  aboutCompany,
  responsibilities,
  startDate,
  endDate,
  selectedFilters,
}) => {
  const duration = getDurationString(startDate, endDate)
  const durationDates = getDurationPeriodsString(startDate, endDate)

  const renderSection = (
    title: string,
    items: { publicName: string; icon?: Maybe<string> | undefined }[]
  ) => (
    <>
      <Typography
        variant={EnumTypographyVariants.P2}
        className={s.WorkExperienceCard__subtitle}
        text={title}
      />

      <div className={s.WorkExperienceCard__section}>
        {items.map((l, i) => (
          <Typography
            key={i}
            variant={EnumTypographyVariants.P3}
            className={clsx(s.WorkExperienceCard__card, {
              [s.WorkExperienceCard__card_selected]: selectedFilters?.includes(
                l.publicName.toLowerCase()
              ),
            })}
            as="div"
          >
            {l.icon && (
              <div className={s.WorkExperienceCard__cardIcon}>
                <img src={l.icon} alt="" />
              </div>
            )}

            {l.publicName}
          </Typography>
        ))}
      </div>
    </>
  )

  return (
    <article className={s.WorkExperienceCard}>
      <div className={s.WorkExperienceCard__circle} />
      <div className={s.WorkExperienceCard__line} />

      <div className={s.WorkExperienceCard__inner}>
        <Typography
          variant={EnumTypographyVariants.P1}
          mobileVariant={EnumTypographyVariants.P2}
          className={s.WorkExperienceCard__position}
          text={position}
        />

        {!!Domains && (
          <div className={s.WorkExperienceCard__domains}>
            {Domains.map(({ publicName, id }) => (
              <Tag
                key={id}
                className={s.WorkExperienceCard__domain}
                text={publicName}
                isSelected
              />
            ))}
          </div>
        )}

        <Typography
          variant={EnumTypographyVariants.P3}
          className={s.WorkExperienceCard__companyName}
          as="div"
        >
          <div className={s.WorkExperienceCard__companyIcon}>
            <BriefCaseIcon />
          </div>

          {companyName}
        </Typography>

        {(!!duration || !!durationDates) && (
          <Typography
            variant={EnumTypographyVariants.P3}
            mobileVariant={EnumTypographyVariants.CAPTION}
            className={s.WorkExperienceCard__date}
            as="div"
          >
            <div className={s.WorkExperienceCard__clockIcon}>
              <ClockIcon />
            </div>

            {duration && (
              <>
                {duration}

                <span className={s.WorkExperienceCard_verticalLine}>
                  &#124;
                </span>
              </>
            )}

            {durationDates}
          </Typography>
        )}

        {description && !(aboutCompany || responsibilities) && (
          <Typography
            variant={EnumTypographyVariants.P3}
            className={s.WorkExperienceCard__description}
            dangerouslySetInnerHTML={{
              __html: getHTMLFromResumeText(description),
            }}
          />
        )}

        {aboutCompany && (
          <>
            <Typography
              variant={EnumTypographyVariants.P2}
              className={s.WorkExperienceCard__subtitle}
              text={'About company'}
            />

            <Typography
              variant={EnumTypographyVariants.P3}
              className={s.WorkExperienceCard__description}
              dangerouslySetInnerHTML={{
                __html: getHTMLFromResumeText(aboutCompany),
              }}
            />
          </>
        )}

        {responsibilities && (
          <>
            <Typography
              variant={EnumTypographyVariants.P2}
              className={s.WorkExperienceCard__subtitle}
              text={'Responsibilities'}
            />
            <Typography
              variant={EnumTypographyVariants.P3}
              className={s.WorkExperienceCard__description}
              dangerouslySetInnerHTML={{
                __html: getHTMLFromResumeText(responsibilities),
              }}
            />
          </>
        )}

        {!!Languages?.length && renderSection('Languages', Languages)}
        {!!Technologies?.length && renderSection('Technologies', Technologies)}
      </div>
    </article>
  )
}
