import React from 'react'

import { ReactComponent as ResumeIcon } from 'assets/icons/simple/resumeIcon.svg'
import { Maybe, Work } from 'graphql/types'

import { CandidateResumeSection } from '../CandidateResumeSection/CandidateResumeSection'

import { ProjectCard } from './ProjectCard/ProjectCard'

export interface ICandidateProjectsProps {
  projects?: Maybe<Work[]>
}

export const CandidateProjects: React.FC<ICandidateProjectsProps> = ({
  projects,
}) => {
  if (!projects?.length) {
    return null
  }

  return (
    <CandidateResumeSection title="Projects" icon={<ResumeIcon />}>
      {projects.map((work, i) => (
        <ProjectCard key={i} {...work} />
      ))}
    </CandidateResumeSection>
  )
}
