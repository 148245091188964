import { gql } from '@apollo/client'

import {
  FRAGMENT_RESUME_FULL,
  FRAGMENT_RESUME_RESULT,
} from 'graphql/fragments/resumeFragment'
import { Resume } from 'graphql/types'

export interface IQueryGetResumeResponse {
  result: Resume
}

export interface IQueryGetResumeVars {
  id: string
}

export const QUERY_GET_RESUME = gql`
  query getResume($id: ID!) {
    result: getResume(id: $id) {
      ...FragmentResumeFull
    }
  }

  ${FRAGMENT_RESUME_FULL}
`

export interface IQueryGetResumeResultResponse {
  result: Pick<Resume, 'firstName' | 'lastName' | 'VettedSkills'>
}

export const QUERY_GET_RESUME_RESULT = gql`
  query getResume($id: ID!) {
    result: getResume(id: $id) {
      ...FragmentResumeResult
    }
  }

  ${FRAGMENT_RESUME_RESULT}
`
