import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import en from '../../../utils/translate/en.json'
import ru from '../../../utils/translate/ru.json'

const resources = {
  en: { translation: en },
  ru: { translation: ru },
}

export const RU_TAGS = ['ru', 'ru-RU', 'ru-ru']

const getInitLanguage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const locale = localStorage.getItem('locale')

  if (locale) {
    return locale
  } else {
    const lang = RU_TAGS.includes(navigator.language) ? 'ru' : 'en'
    localStorage.setItem('locale', lang)

    return lang
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: getInitLanguage(),
  fallbackLng: 'en',
})

export default i18n
