import { ApolloClient, from, InMemoryCache, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

import { authLink } from './links/authLink'
import { errorLink } from './links/errorLink'
import { httpLink } from './links/httpLink'
import { retryLink } from './links/retryLink'
import { wsLink } from './links/wsLink'

function createApolloClient() {
  return new ApolloClient({
    // link: split([errorLink, authLink, httpLink, wsLink]), // Don't replace "httpLink" and "wsLink"
    link: from([
      errorLink,
      retryLink,
      authLink,
      split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query)
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          )
        },
        wsLink,
        httpLink
      ),
    ]),
    cache: new InMemoryCache(),
  })
}

export const apolloClient = createApolloClient()
