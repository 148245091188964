import React, { useRef } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import { useSpring } from 'react-spring'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
  useIntersectionObserver,
  ProgressCircle,
  ProgressLine,
  EnumBreakpoints,
  useMediaQuery,
} from '@insquad/tools'

import { ReactComponent as HelpSIcon } from 'assets/icons/simple/helpSIcon.svg'
import { VettedSkillStep } from 'graphql/types'

import s from './ChallengeInfo.module.scss'

const getTime = (time: number, isCoding: boolean): string => {
  const h = Math.floor(time / 3600)
  let m = Math.floor((time % 3600) / 60)
  const s = Math.floor((time % 3600) % 60)
  if (isCoding) {
    m += s ? 1 : 0
    return `${h ? `${h} h ` : ''}${m ? `${m} m` : ''}`
  }
  return `${h ? `${h} h ` : ''}${m ? `${m} m ` : ''} ${s} s`
}

const isNotInTime = (a: number, b: number): boolean => {
  return a / b > 1
}

export interface IChallengeInfoProps {
  title: string
  step: VettedSkillStep
  children: React.ReactNode
  isOneStep: boolean
  isOpen: boolean
}

export const ChallengeInfo: React.FC<IChallengeInfoProps> = ({
  title,
  step,
  children,
  isOneStep,
  isOpen,
}) => {
  const trigerRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(trigerRef, {})
  const isVisible = !!entry?.isIntersecting

  const tagStyle = [
    s.ChallengeInfo__tag_blue,
    s.ChallengeInfo__tag_green,
    s.ChallengeInfo__tag_violet,
  ]

  const getPercentLine = (time: number, averageTime: number) => {
    const percent = +((time / averageTime) * 100).toFixed()
    return percent >= 100 ? 100 : percent
  }

  const { getTooltipProps, setTriggerRef, setTooltipRef, visible } =
    usePopperTooltip({
      placement: 'top-start',
      offset: [-100, 18],
    })

  const isDesktop = useMediaQuery(EnumBreakpoints.BREAKPOINT_LG, 'min')

  const NetTimeProgressLine = () => {
    return (
      <div className={s.ChallengeInfo__time}>
        <Typography
          variant={EnumTypographyVariants.CAPTION}
          className={clsx(
            s.ChallengeInfo__chartSubtitle,
            s.ChallengeInfo__quantityTime_green
          )}
          ref={setTriggerRef}
          as="div"
        >
          Net time
          <HelpSIcon className={s.ChallengeInfo__helpIcon} />
          {visible && (
            <Typography
              {...getTooltipProps({ ref: setTooltipRef })}
              variant={EnumTypographyVariants.P3}
              className={s.ChallengeInfo__tooltip}
              text="The time a candidate spent working on the coding challenge keeping the tab active"
              as="div"
            />
          )}
        </Typography>

        <ProgressLine
          animatedStyle={useSpring({
            to: {
              width: `${
                !!step.netTime && !!step.averageTime
                  ? getPercentLine(step.netTime, step.averageTime)
                  : 0
              }%`,
            },
            from: {
              width: '0%',
            },
            config: { duration: 1000 },
            cancel: !isOpen && !isVisible,
            reset: !isOpen,
          })}
          classes={{
            secondaryLineClass: s.ChallengeInfo__chartTime_green,
            lineClass: s.ChallengeInfo__line,
          }}
        />
        <Typography
          variant={EnumTypographyVariants.CAPTION}
          className={clsx(
            s.ChallengeInfo__quantityTime,
            s.ChallengeInfo__quantityTime_green
          )}
        >
          {!!step.netTime && getTime(step.netTime, true)}
        </Typography>
      </div>
    )
  }

  const renderTimeSection = (isOneStep: boolean) => {
    return (
      <div
        className={clsx(s.ChallengeInfo__timeSection, {
          [s.ChallengeInfo__timeSection_optinal]: isOneStep,
        })}
      >
        <div className={s.ChallengeInfo__time}>
          <Typography
            variant={EnumTypographyVariants.P2}
            text="Time"
            className={s.ChallengeInfo__chartSubtitle}
          />
          <Typography
            variant={EnumTypographyVariants.H4}
            className={clsx(
              s.ChallengeInfo__quantityTime,
              s.ChallengeInfo__quantityTime_blue,
              {
                [s.ChallengeInfo__quantityTime_orange]: isNotInTime(
                  step.duration || 1,
                  step.averageTime || 1
                ),
              }
            )}
          >
            {!!step.duration &&
              getTime(step.duration, step.stepType === 'CODING')}
          </Typography>
        </div>
        <div className={s.ChallengeInfo__time}>
          <Typography
            variant={EnumTypographyVariants.CAPTION}
            text="Total time"
            className={clsx(
              s.ChallengeInfo__chartSubtitle,
              s.ChallengeInfo__quantityTime_blue,
              {
                [s.ChallengeInfo__quantityTime_orange]: isNotInTime(
                  step.duration || 1,
                  step.averageTime || 1
                ),
              }
            )}
          />
          <ProgressLine
            // eslint-disable-next-line react-hooks/rules-of-hooks
            animatedStyle={useSpring({
              width: `${
                !!step.duration && !!step.averageTime
                  ? getPercentLine(step.duration, step.averageTime)
                  : 0
              }%`,
              from: {
                width: '0%',
              },
              config: { duration: 1000 },
              cancel: !isOpen && !isVisible,
              reset: !isOpen,
            })}
            classes={{
              secondaryLineClass: isNotInTime(
                step.duration || 1,
                step.averageTime || 1
              )
                ? s.ChallengeInfo__chartTime_orange
                : s.ChallengeInfo__chartTime_blue,
              lineClass: s.ChallengeInfo__line,
            }}
          />
          <Typography
            variant={EnumTypographyVariants.CAPTION}
            className={clsx(
              s.ChallengeInfo__quantityTime,
              s.ChallengeInfo__quantityTime_blue,
              {
                [s.ChallengeInfo__quantityTime_orange]: isNotInTime(
                  step.duration || 1,
                  step.averageTime || 1
                ),
              }
            )}
          >
            {!!step.duration &&
              getTime(step.duration, step.stepType === 'CODING')}
          </Typography>
        </div>
        {step.stepType === 'CODING' && NetTimeProgressLine()}
        <div className={s.ChallengeInfo__time}>
          <Typography
            variant={EnumTypographyVariants.CAPTION}
            text="Average time"
            className={clsx(
              s.ChallengeInfo__chartSubtitle,
              s.ChallengeInfo__quantityTime_darkGrey
            )}
          />
          <ProgressLine
            fixedPercentage={0}
            classes={{
              lineClass: s.ChallengeInfo__line,
            }}
          />
          <Typography
            variant={EnumTypographyVariants.CAPTION}
            className={clsx(
              s.ChallengeInfo__quantityTime,
              s.ChallengeInfo__quantityTime_darkGrey
            )}
          >
            {!!step.averageTime &&
              getTime(step.averageTime, step.stepType === 'CODING')}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div
      className={clsx(s.ChallengeInfo, {
        [s.ChallengeInfo_optinal]: isOneStep,
      })}
    >
      <div className={s.ChallengeInfo__title}>
        {!isOneStep && (
          <div className={s.ChallengeInfo__icon}>
            <Typography
              variant={EnumTypographyVariants.P1}
              text={step.stepNumber}
            />
          </div>
        )}
        <Typography variant={EnumTypographyVariants.H4} text={title} />
      </div>
      <div
        className={clsx(s.ChallengeInfo__section, {
          [s.ChallengeInfo__section_optinal]: isOneStep,
        })}
        ref={trigerRef}
      >
        {step.tasks.map((task, i) => (
          <div key={i} className={s.ChallengeInfo__progress}>
            <ProgressCircle
              percentage={+((task.score / task.maxScore) * 100).toFixed()}
              color="blue"
              strokeWidth={6}
              size={isDesktop ? 83 : 64}
              isRound
              classes={{ svgClassName: s.ChallengeInfo__circle }}
              score={task.score}
              maxScore={task.maxScore}
              text={step.stepType === 'QUIZ' ? `QUIZ` : ''}
              isAnimated
              isVisible={isVisible}
              isOpen={isOpen}
            />
            <div className={s.ChallengeInfo__tagsSection}>
              {step.stepType === 'CODING' && (
                <Typography
                  className={s.ChallengeInfo__tagsTitle}
                  variant={EnumTypographyVariants.P2}
                  text={`Task ${i + 1}`}
                />
              )}
              <div className={s.ChallengeInfo__tags}>
                {task.tags?.map((tag, i) => (
                  <div
                    key={i}
                    className={clsx(
                      s.ChallengeInfo__tag,
                      tagStyle[i > 2 ? i - 3 : i]
                    )}
                  >
                    <Typography
                      variant={EnumTypographyVariants.CAPTION}
                      text={tag.name[0].toUpperCase() + tag.name.slice(1)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        {isOneStep && renderTimeSection(isOneStep)}
      </div>
      {!isOneStep && renderTimeSection(isOneStep)}
      {children}
    </div>
  )
}
