import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePopperTooltip } from 'react-popper-tooltip'

import clsx from 'clsx'

import { useAuth0 } from '@auth0/auth0-react'
import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { ReactComponent as ChevronIcon } from 'assets/icons/simple/chevronIcon.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/simple/logoutIcon.svg'
import {
  AvatarWithName,
  IAvatarWithNameProps,
} from 'common/components/Avatar/_components/AvatarWithName/AvatarWithName'
import { UserType } from 'graphql/types'
import { trimStringWithEllipsis } from 'utils/string'

import s from './AvatarWithLogout.module.scss'

export interface IAvatarWithLogoutProps extends IAvatarWithNameProps {
  role?: UserType
}

export const AvatarWithLogout: React.FC<IAvatarWithLogoutProps> = ({
  firstNameLine = '',
  secondNameLine = '',
  role,
  ...otherProps
}) => {
  const { logout } = useAuth0()
  const { t } = useTranslation()

  const { setTriggerRef, setTooltipRef, getTooltipProps, visible } =
    usePopperTooltip({
      interactive: true,
      placement: 'bottom-end',
      offset: [0, 20],
      delayHide: 260,
    })

  const handleLogout = () => {
    logout({
      returnTo:
        window.location.origin +
        (role ? `?role=${role.toLocaleLowerCase()}` : ''),
    })
  }

  return (
    <div className={s.AvatarWithLogout__logout} ref={setTriggerRef}>
      <AvatarWithName
        {...otherProps}
        wrapperClassName={s.AvatarWithLogout__avatar}
        firstNameLine={trimStringWithEllipsis(firstNameLine, 26)}
        secondNameLine={trimStringWithEllipsis(secondNameLine, 26)}
      />

      <div
        className={clsx(s.AvatarWithLogout__icon, {
          [s.AvatarWithLogout__icon_active]: visible,
        })}
      >
        <ChevronIcon />
      </div>

      {visible && (
        <div
          {...getTooltipProps()}
          ref={setTooltipRef}
          className={s.AvatarWithLogout__tooltip}
          onClick={handleLogout}
        >
          <div className={s.AvatarWithLogout__tooltipIcon}>
            <LogoutIcon />
          </div>

          <Typography variant={EnumTypographyVariants.P1} text={t('log_out')} />
        </div>
      )}
    </div>
  )
}
