import React from 'react'

import { ReactComponent as BookIcon } from 'assets/icons/simple/bookIcon.svg'
import { Education, Maybe } from 'graphql/types'

import { CandidateResumeSection } from '../CandidateResumeSection/CandidateResumeSection'

import { EducationCard } from './EducationCard/EducationCard'

import s from './CandidateResumeEducation.module.scss'

export interface ICandidateResumeEducationProps {
  education: Maybe<Array<Education>> | undefined
}

export const CandidateResumeEducation: React.FC<
  ICandidateResumeEducationProps
> = ({ education }) => {
  if (!education?.length) {
    return null
  }

  return (
    <CandidateResumeSection
      title="Education"
      icon={<BookIcon />}
      contentClassName={s.CandidateResumeEducation__content}
    >
      {education.map((e, i) => (
        <EducationCard
          key={i}
          className={s.CandidateResumeEducation__card}
          {...e}
        />
      ))}
    </CandidateResumeSection>
  )
}
