import React from 'react'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { Avatar } from 'common/components/Avatar'
import { Interviewer } from 'graphql/types'

import s from './InterviewerCard.module.scss'

const TOPICS = [
  'Previous work experience review',
  'Hard skills self-assessment',
  'Expectations from the future project and team',
  'Cultural values and business ethics',
]

export interface IInterviewerCardProps extends Interviewer {}

export const InterviewerCard: React.FC<IInterviewerCardProps> = ({
  name,
  avatar,
  title,
}) => {
  if (!name) {
    return null
  }

  return (
    <article className={s.InterviewerCard}>
      <div className={s.InterviewerCard__header}>
        {avatar && (
          <Avatar
            imageSrc={avatar}
            width={50}
            className={s.InterviewerCard__img}
          />
        )}

        <div>
          <Typography variant={EnumTypographyVariants.P2} text={name} />

          {title && (
            <Typography
              variant={EnumTypographyVariants.P3}
              className={s.InterviewerCard__role}
              text={title}
            />
          )}
        </div>
      </div>

      <Typography
        variant={EnumTypographyVariants.CAPTION}
        className={s.InterviewerCard__badge}
        text="15 min"
      />

      <Typography
        variant={EnumTypographyVariants.P3}
        text="Topics covered in interview"
        className={s.InterviewerCard__topicsTitle}
      />

      <ul className={s.InterviewerCard__list}>
        {TOPICS.map((t, i) => (
          <Typography
            key={i}
            text={t}
            variant={EnumTypographyVariants.P3}
            as="li"
          />
        ))}
      </ul>
    </article>
  )
}
