import { gql } from '@apollo/client'

import { FRAGMENT_LOGIN_FULL } from 'graphql/fragments/loginFragment'
import { Login } from 'graphql/types'

export interface IQueryGetLoginResponse {
  result: Login
}

export interface IQueryGetLoginVars {}

export const QUERY_GET_LOGIN = gql`
  query getLogin {
    result: getLogin {
      ...FragmentLoginFull
    }
  }

  ${FRAGMENT_LOGIN_FULL}
`
