import React from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography, Spinner } from '@insquad/tools'

import {
  EnumTextButtonVariants,
  ITextButtonProps,
  TextButtonDefaultAsType,
} from './types'

import s from './TextButton.module.scss'

export const TextButton = <
  E extends React.ElementType = TextButtonDefaultAsType
>({
  as,
  children,
  className,
  classes,
  iconLeft,
  iconRight,
  innerRef,
  disabled,
  isLoading,
  variant = EnumTextButtonVariants.PRIMARY,
  contentVariant = EnumTypographyVariants.P3,
  ...otherProps
}: ITextButtonProps<E>) => {
  const Component = as || TextButtonDefaultAsType

  const _disabled = isLoading || disabled

  return (
    <Component
      ref={innerRef}
      className={clsx(
        s.TextButton,
        s[`TextButton_${variant}`],
        { [s.TextButton_disabled]: _disabled },
        classes?.root,
        className
      )}
      disabled={_disabled}
      {...otherProps}
    >
      {iconLeft && !isLoading && (
        <div
          className={clsx(
            s.TextButton__icon,
            s.TextButton__iconLeft,
            classes?.iconLeft
          )}
        >
          {iconLeft}
        </div>
      )}

      <Typography
        className={clsx(classes?.content)}
        variant={contentVariant}
        as="div"
      >
        {children}
      </Typography>

      {iconRight && !isLoading && (
        <div
          className={clsx(
            s.TextButton__icon,
            s.TextButton__iconRight,
            classes?.iconRight
          )}
        >
          {iconRight}
        </div>
      )}

      {isLoading && (
        <div
          className={clsx(
            s.TextButton__icon,
            s.TextButton__iconRight,
            classes?.iconRight
          )}
        >
          <Spinner size="sm" />
        </div>
      )}
    </Component>
  )
}

TextButton.Variant = EnumTextButtonVariants
