export enum EnumCandidateRoutes {
  CANDIDATE_DASHBOARD_ROUTE = '/',
  CANDIDATE_SKILLS_ASSESSMENT_ROUTE = '/skills',
  // DEVELOPER_RESUME_ROUTE = '/resume',
  CANDIDATE_HELP_CENTER_ROUTE = '/help-center',
  CANDIDATE_PROFILE_ROUTE = '?profile',
  CANDIDATE_INVITE_FRIEND_ROUTE = '?invite-friend',
}

export enum EnumPublicRoutes {
  CANDIDATE_RESUME_ROUTE = '/developer-profile/:id',
  CANDIDATE_SHARE_PROFILE_ROUTE = '/share-profile/:id',
  CANDIDATE_SHARE_RESULT_ROUTE = '/share-result',
}

export enum EnumCompanyRoutes {
  COMPANY_CANDIDATES_ROUTE = '/',
  COMPANY_HELP_CENTER_ROUTE = '/help-center',
  COMPANY_INVITE_MEMBER_ROUTE = '?invite-member',
}

export enum EnumLandingRoutes {
  SALES_LANDING_ROUTE = '/:slug',
}
