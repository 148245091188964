import React from 'react'

import {
  EnumTypographyVariants,
  Typography,
  Button,
  EnumButtonVariants,
} from '@insquad/tools'

import { BannerAnimation } from 'common/animations/BannerAnimation/BannerAnimation'
import { Logo } from 'common/components/Logo/Logo'
import { UserType } from 'graphql/types'

import DevelopersImgSrc from './developers.png'

import s from './ShareBanner.module.scss'

export interface IShareBannerProps {
  onSignUp: (userType: UserType) => () => void
}

export const ShareBanner: React.FC<IShareBannerProps> = ({ onSignUp }) => (
  <BannerAnimation
    isVisible
    rootProps={{
      className: s.ShareBanner,
    }}
  >
    <div className={s.ShareBanner__content}>
      <Logo className={s.ShareBanner__logo} />

      <Typography
        variant={EnumTypographyVariants.H1}
        text="1,000+ developers have already passed all the vetting stages at Insquad"
        className={s.ShareBanner__title}
        as="h2"
      />

      <Typography
        variant={EnumTypographyVariants.P1}
        className={s.ShareBanner__description}
        text="Sign up now for the fastest, easiest way to hire vetted, senior remote developers."
      />

      <div className={s.ShareBanner__btns}>
        <Button
          onClick={onSignUp(UserType.Company)}
          className={s.ShareBanner__btn}
        >
          Hire developers
        </Button>

        <Button
          onClick={onSignUp(UserType.Candidate)}
          className={s.ShareBanner__btn}
          variant={EnumButtonVariants.SECONDARY}
        >
          Apply for a job
        </Button>
      </div>
    </div>

    <div className={s.ShareBanner__img}>
      <img src={DevelopersImgSrc} alt="" />
    </div>
  </BannerAnimation>
)
