import React, { useEffect } from 'react'

import clsx from 'clsx'

import { ISpinnerProps, Spinner, useToggle } from '@insquad/tools'

import { ReactComponent as ImagePlaceholderIcon } from 'assets/icons/simple/imagePlaceholderIcon.svg'
import { Maybe } from 'graphql/types'
import { IntrinsicPropsWithoutRef } from 'utils/reactTypes'

import s from './ImgPreview.module.scss'

export interface IImgPreviewClasses {
  root?: string
  rootLoaded?: string
  rootLoading?: string
  rootFailed?: string
  image?: string
  placeholder?: string
  spinner?: string
}

export interface IImgPreviewProps extends IntrinsicPropsWithoutRef<'div'> {
  imageSrc?: Maybe<string>
  placeholderElement?: React.ReactNode
  spinnerProps?: ISpinnerProps
  classes?: IImgPreviewClasses
}

export const ImgPreview: React.FC<IImgPreviewProps> = ({
  imageSrc,
  classes,
  className,
  spinnerProps,
  placeholderElement,
  ...otherProps
}) => {
  const loadedToggler = useToggle()
  const errorToggler = useToggle()

  useEffect(() => {
    const img = new Image()

    if (imageSrc) {
      img.src = imageSrc
      img.addEventListener('load', loadedToggler.set)
      img.addEventListener('error', errorToggler.set)
    }

    return () => {
      img.removeEventListener('load', loadedToggler.set)
      img.removeEventListener('error', errorToggler.set)
    }
  }, [imageSrc, loadedToggler, errorToggler])

  const isImgLoaded = loadedToggler.value && !errorToggler.value
  const isImgLoading = imageSrc && !loadedToggler.value && !errorToggler.value
  const isImgFailed = !imageSrc || errorToggler.value

  return (
    <div
      className={clsx(
        s.ImgPreview,
        className,
        {
          [classes?.rootLoaded || '']: isImgLoaded,
          [classes?.rootLoading || '']: isImgLoading,
          [classes?.rootFailed || '']: isImgFailed,
        },
        classes?.root
      )}
      {...otherProps}
    >
      {isImgFailed && (
        <div className={clsx(s.ImgPreview__placeholder, classes?.placeholder)}>
          {placeholderElement || <ImagePlaceholderIcon />}
        </div>
      )}

      {isImgLoading && (
        <div className={clsx(s.ImgPreview__spinner, classes?.spinner)}>
          <Spinner {...spinnerProps} />
        </div>
      )}

      <div
        className={clsx(
          s.ImgPreview__img,
          {
            [s.ImgPreview__img_loaded]: isImgLoaded,
          },
          classes?.image
        )}
        style={
          imageSrc && !errorToggler.value
            ? {
                backgroundImage: `url(${imageSrc})`,
              }
            : {}
        }
      />
    </div>
  )
}
