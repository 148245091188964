import React from 'react'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { Maybe, Work } from 'graphql/types'

import { getHTMLFromResumeText } from '../../_utils'

import s from './ProjectCard.module.scss'

export interface IProjectCardProps extends Work {}

export const ProjectCard: React.FC<IProjectCardProps> = ({
  companyName,
  description,
  position,
  Languages,
  Technologies,
}) => {
  const renderSection = (
    title: string,
    items: { publicName: string; icon?: Maybe<string> | undefined }[]
  ) => (
    <>
      <Typography
        variant={EnumTypographyVariants.P2}
        className={s.ProjectCard__subtitle}
        text={title}
      />

      <div className={s.ProjectCard__section}>
        {items.map((l, i) => (
          <Typography
            key={i}
            variant={EnumTypographyVariants.P3}
            className={s.ProjectCard__card}
            as="div"
          >
            {l.icon && (
              <div className={s.ProjectCard__cardIcon}>
                <img src={l.icon} alt="" />
              </div>
            )}

            {l.publicName}
          </Typography>
        ))}
      </div>
    </>
  )

  return (
    <article className={s.ProjectCard}>
      <div className={s.ProjectCard__circle} />
      <div className={s.ProjectCard__line} />

      <div className={s.ProjectCard__inner}>
        <Typography
          variant={EnumTypographyVariants.P1}
          mobileVariant={EnumTypographyVariants.P2}
          className={s.ProjectCard__position}
          text={position}
        />

        <Typography
          variant={EnumTypographyVariants.P3}
          className={s.ProjectCard__companyName}
        >
          {companyName}
        </Typography>

        {description && (
          <Typography
            variant={EnumTypographyVariants.P3}
            className={s.ProjectCard__description}
            dangerouslySetInnerHTML={{
              __html: getHTMLFromResumeText(description),
            }}
          />
        )}

        {!!Languages?.length && renderSection('Languages', Languages)}
        {!!Technologies?.length && renderSection('Technologies', Technologies)}
      </div>
    </article>
  )
}
