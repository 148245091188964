import TagManager from 'react-gtm-module'

import amplitude from 'amplitude-js'

import { EnumAnalyticCompanyEvent } from 'common/enums/analyticEventEnum'
import { REACT_APP_GTM_COMPANY } from 'utils/config'

import { IAnalytic, ICompanyAnalyticProperty } from './types'

export class CompanyAnalytic implements IAnalytic {
  private loginGuid = ''
  private gtmEvents = [
    EnumAnalyticCompanyEvent.BOOK_DEMO_INTERVIEW_CLICK,
    EnumAnalyticCompanyEvent.BOOKED_DEMO_INTERVIEW,
    EnumAnalyticCompanyEvent.SIGN_UP_COMPLETE,
    EnumAnalyticCompanyEvent.BOOK_CALL_CLICK,
  ]

  constructor(key: string) {
    TagManager.initialize({
      gtmId: REACT_APP_GTM_COMPANY,
    })

    amplitude.getInstance().init(key, undefined, {
      deviceIdFromUrlParam: true,
      saveEvents: true,
      includeUtm: true,
      includeGclid: true,
      includeReferrer: true,
    })

    amplitude.getInstance().logEvent('session_start')
  }

  setUserId(loginGuid: string) {
    amplitude.getInstance().setUserId(loginGuid)
    this.loginGuid = loginGuid
  }

  event(
    event: EnumAnalyticCompanyEvent,
    properties?: Record<string, string | string[]>
  ) {
    amplitude.getInstance().logEvent(event, properties)

    const isGtmEvent = this.gtmEvents.find((e) => e === event)

    if (isGtmEvent) {
      window.dataLayer.push({
        event,
        properties,
        userId: this.loginGuid,
      })
    }
  }

  userProperty({ key, value }: ICompanyAnalyticProperty) {
    const identify = new amplitude.Identify().set(key, value)
    amplitude.getInstance().identify(identify)
  }

  userPropertyOnce({ key, value }: ICompanyAnalyticProperty) {
    const identify = new amplitude.Identify().setOnce(key, value)
    amplitude.getInstance().identify(identify)
  }
}
