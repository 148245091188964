import React from 'react'

import clsx from 'clsx'

import s from './LevelScore.module.scss'

const getLevelInfo = (level: string) => {
  if (level === 'Advanced') {
    return {
      filledLevel: new Array(1).fill(1),
      emptyLevel: new Array(2).fill(1),
    }
  }
  if (level === 'Pro') {
    return {
      filledLevel: new Array(2).fill(1),
      emptyLevel: new Array(1).fill(1),
    }
  }
  return {
    filledLevel: new Array(3).fill(1),
    emptyLevel: new Array(0).fill(1),
  }
}

export interface ILevelScoreProps {
  level: string
  className?: string
}

export const LevelScore: React.FC<ILevelScoreProps> = ({
  level,
  className,
}) => {
  const { filledLevel, emptyLevel } = getLevelInfo(level)
  return (
    <div className={clsx(s.LevelScore)}>
      {filledLevel.map((_, i) => (
        <div className={clsx(className, s.LevelScore__block)} key={i} />
      ))}

      {emptyLevel.map((_, i) => (
        <div
          className={clsx(s.LevelScore_default, s.LevelScore__block)}
          key={i}
        />
      ))}
    </div>
  )
}
