import { gql } from '@apollo/client'

import { FRAGMENT_INTERVIEW_SESSION } from './interviewSessionFragment'

export const FRAGMENT_COMPANY_FULL = gql`
  fragment FragmentCompanyFull on Company {
    id
    name
    position
    isQualified
    inviteDemoUrl
    InterviewSession {
      ...FragmentInterviewSession
    }
  }

  ${FRAGMENT_INTERVIEW_SESSION}
`
