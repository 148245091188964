import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client'

import {
  IQueryGetLoginResponse,
  IQueryGetLoginVars,
  QUERY_GET_LOGIN,
} from '../gql/getLogin'

export const useGetLoginQuery = (
  options?: QueryHookOptions<IQueryGetLoginResponse, IQueryGetLoginVars>
) => {
  return useQuery<IQueryGetLoginResponse, IQueryGetLoginVars>(
    QUERY_GET_LOGIN,
    options
  )
}

export const useGetLoginLazyQuery = (
  options?: QueryHookOptions<IQueryGetLoginResponse, IQueryGetLoginVars>
) => {
  return useLazyQuery<IQueryGetLoginResponse, IQueryGetLoginVars>(
    QUERY_GET_LOGIN,
    options
  )
}

export const useLoginInfo = () => {
  const { data: loginData } = useGetLoginQuery()

  return {
    phone: loginData?.result.phone || '',
    email: loginData?.result.email || '',
    firstName: loginData?.result.firstName || '',
    lastName: loginData?.result.lastName || '',
  }
}
