import {
  QueryHookOptions,
  useApolloClient,
  useLazyQuery,
  useQuery,
} from '@apollo/client'

import { Resume } from 'graphql/types'

import {
  IQueryGetResumeResponse,
  IQueryGetResumeVars,
  QUERY_GET_RESUME,
  QUERY_GET_RESUME_RESULT,
} from '../gql/getResume'
import {
  IQueryGetResumeWithPhoneResponse,
  IQueryGetResumeWithPhoneVars,
  QUERY_GET_RESUME_WITH_PHONE,
} from '../gql/getResumeWithPhone'

export const useResumeQuery = (
  options?: QueryHookOptions<IQueryGetResumeResponse, IQueryGetResumeVars>
) => {
  return useQuery<IQueryGetResumeResponse, IQueryGetResumeVars>(
    QUERY_GET_RESUME,
    options
  )
}

export const useResumeLazyQuery = (
  options?: QueryHookOptions<IQueryGetResumeResponse, IQueryGetResumeVars>
) => {
  return useLazyQuery<IQueryGetResumeResponse, IQueryGetResumeVars>(
    QUERY_GET_RESUME,
    options
  )
}

export const useResumeWithPhoneQuery = (
  options?: QueryHookOptions<
    IQueryGetResumeWithPhoneResponse,
    IQueryGetResumeWithPhoneVars
  >
) => {
  return useQuery<
    IQueryGetResumeWithPhoneResponse,
    IQueryGetResumeWithPhoneVars
  >(QUERY_GET_RESUME_WITH_PHONE, options)
}

export const useUpdateResumeCache = () => {
  const apolloClient = useApolloClient()

  const handleResumeCacheUpdate = (
    resumeId: string,
    resumePartial: Partial<Resume>
  ) => {
    const resumeCache = apolloClient.readQuery<
      IQueryGetResumeResponse,
      IQueryGetResumeVars
    >({
      query: QUERY_GET_RESUME,
      variables: {
        id: resumeId,
      },
    })

    if (resumeCache) {
      const newCache = {} as Record<string, unknown>

      let key: keyof Resume
      for (key in resumeCache.result) {
        const updateValue = resumePartial[key]

        if (typeof updateValue !== 'undefined') {
          newCache[key] = updateValue
        } else {
          newCache[key] = resumeCache.result[key]
        }
      }

      apolloClient.writeQuery<IQueryGetResumeResponse, IQueryGetResumeVars>({
        query: QUERY_GET_RESUME,
        variables: {
          id: resumeId,
        },
        data: {
          result: newCache as Resume,
        },
      })
    }
  }

  return {
    handleResumeCacheUpdate,
  }
}

export const useResumeResultQuery = (
  options?: QueryHookOptions<IQueryGetResumeResponse, IQueryGetResumeVars>
) => {
  return useQuery<IQueryGetResumeResponse, IQueryGetResumeVars>(
    QUERY_GET_RESUME_RESULT,
    options
  )
}
