import React from 'react'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { ReactComponent as MailIcon } from 'assets/icons/simple/mailIcon.svg'
import { ReactComponent as PhoneIcon } from 'assets/icons/simple/phoneIcon.svg'
import { Maybe } from 'graphql/types'

import s from './ResumeContacts.module.scss'

export interface IResumeContactsProps {
  email: Maybe<string> | undefined
  phone: Maybe<string> | undefined
}

export const ResumeContacts: React.FC<IResumeContactsProps> = ({
  email,
  phone,
}) => {
  if (!email && !phone) {
    return null
  }

  const renderItem = (anchor: JSX.Element, icon: JSX.Element) => (
    <Typography
      className={s.ResumeContacts__item}
      variant={EnumTypographyVariants.P2}
      as="li"
    >
      <div className={s.ResumeContacts__icon}>{icon}</div>

      {anchor}
    </Typography>
  )

  return (
    <div className={s.ResumeContacts}>
      <ul className={s.ResumeContacts__inner}>
        {email &&
          renderItem(<a href={`mailto:${email}`}>{email}</a>, <MailIcon />)}

        {email &&
          renderItem(<a href={`tel:${phone}`}>{phone}</a>, <PhoneIcon />)}
      </ul>
    </div>
  )
}
