import React from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import s from './TechnologyTitle.module.scss'

export interface ITechnologyTitleProps {
  title: string
  className?: string
}

export const TechnologyTitle: React.FC<ITechnologyTitleProps> = ({
  title,
  className,
}) => {
  return (
    <Typography
      variant={EnumTypographyVariants.H4}
      className={clsx(s.TechnologyTitle, className)}
      as="div"
    >
      {title}
    </Typography>
  )
}
