import { useQuery, QueryHookOptions, useLazyQuery } from '@apollo/client'

import { Experience, Status } from 'graphql/types'

import { IGetCandidateResponse, QUERY_GET_CANDIDATE } from '../gql/getCandidate'

export const useGetCandidateQuery = (
  options?: QueryHookOptions<IGetCandidateResponse, void>
) => {
  return useQuery<IGetCandidateResponse, void>(QUERY_GET_CANDIDATE, options)
}

export const useGetCandidateQueryLazy = (
  options?: QueryHookOptions<IGetCandidateResponse, void>
) => {
  return useLazyQuery<IGetCandidateResponse, void>(QUERY_GET_CANDIDATE, options)
}

// TODO попробовать сделать это на proxy
export const useCandidateInfo = () => {
  const { data: candidateData } = useGetCandidateQuery()

  return {
    id: candidateData?.result.id || '',
    status: candidateData?.result.status || Status.Intro,
    interviewBookUrl: candidateData?.result?.interviewBookUrl || '',
    scheduledAt: candidateData?.result?.InterviewSession?.scheduledAt || '',
    rescheduleUrl: candidateData?.result?.InterviewSession?.rescheduleUrl || '',
    uploadedCv: !!candidateData?.result.uploadedCv,
    noticePeriod: candidateData?.result.noticePeriod || 0,
    experience: candidateData?.result.experience || Experience.LessYear,
    skills: candidateData?.result.CandidateSkills || [],
    locale: candidateData?.result.locale || 'en',
  }
}
