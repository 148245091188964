import { gql } from '@apollo/client'

export const FRAGMENT_INTERVIEWER_FULL = gql`
  fragment FragmentInterviewerFull on Interviewer {
    id
    email
    name
    title
    avatar
  }
`
