import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { EnumTypographyVariants, Typography, Button } from '@insquad/tools'

import DevelopersCollagePng from 'assets/images/developersCollageSmall.png'
import { BannerAnimation } from 'common/animations/BannerAnimation/BannerAnimation'
import { Logo } from 'common/components/Logo/Logo'
import { EnumCompanyQueries } from 'common/enums/companyQueriesEnum'
import { UserType } from 'graphql/types'

import s from './ResumeHireBanner.module.scss'

export interface IResumeHireBannerProps {
  resumeId: string
}

export const ResumeHireBanner: React.FC<IResumeHireBannerProps> = ({
  resumeId,
}) => {
  const { loginWithRedirect } = useAuth0()

  const handleSignUp = () => {
    loginWithRedirect({
      redirectUri:
        window.origin +
        `?${
          EnumCompanyQueries.ROUTE_QUERY_RESUME_ID
        }=${resumeId}&role=${UserType.Company.toLocaleLowerCase()}`,
    })
  }

  return (
    <BannerAnimation
      isVisible
      rootProps={{
        className: s.ResumeHireBanner,
      }}
    >
      <div className={s.ResumeHireBanner__info}>
        <Logo className={s.ResumeHireBanner__logo} isSmall />

        <Typography
          variant={EnumTypographyVariants.H2}
          text="Do you need to hire vetted senior developers?"
          className={s.ResumeHireBanner__title}
        />

        <Typography
          variant={EnumTypographyVariants.P1}
          text="Sign up with Insquad to hire qualified developers from all over the world"
          className={s.ResumeHireBanner__description}
        />

        <Button onClick={handleSignUp} className={s.ResumeHireBanner__btn}>
          Sign up
        </Button>
      </div>

      <img
        src={DevelopersCollagePng}
        className={s.ResumeHireBanner__img}
        alt=""
      />
    </BannerAnimation>
  )
}
