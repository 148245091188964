import { QueryHookOptions, useQuery } from '@apollo/client'

import {
  IGetSalesOfferResponse,
  IQueryGetSalesOfferVars,
  QUERY_GET_SALES_OFFER,
} from '../gql/getSalesOffer'

export const useSalesOfferQuery = (
  options?: QueryHookOptions<IGetSalesOfferResponse, IQueryGetSalesOfferVars>
) => {
  return useQuery<IGetSalesOfferResponse, IQueryGetSalesOfferVars>(
    QUERY_GET_SALES_OFFER,
    options
  )
}

export const useSalesOfferInfo = (slug: string) => {
  const { data, loading } = useSalesOfferQuery({ variables: { slug } })

  return {
    companyName: data?.result.companyName ?? '',
    id: data?.result.id ?? '',
    technology: data?.result.technology ?? '',
    ShortResumes: data?.result.ShortResumes ?? [],
    isLoading: loading,
  }
}
