import create from 'zustand'

import { _queryStringMiddleware } from './_queryStringMiddleware'

export interface ICompanyStoreSkillsFilters {
  technologyIds: Array<string>
  languageIds: Array<string>
  domainIds: Array<string>

  clearSkillsFilters: () => void
  clearDomainsFilters: () => void

  setTechnologiesIds: (newIds: Array<string>) => void
  setLanguageIds: (newIds: Array<string>) => void
  setDomainsIds: (newIds: Array<string>) => void

  setFiltersIds: (
    ids: Partial<
      Pick<
        ICompanyStoreSkillsFilters,
        'domainIds' | 'languageIds' | 'technologyIds'
      >
    >
  ) => void

  clearFilters: () => void
}

export const useCompanyStoreSkillsFilters = create<ICompanyStoreSkillsFilters>(
  _queryStringMiddleware((set) => ({
    domainIds: [],
    languageIds: [],
    technologyIds: [],

    setDomainsIds: (newIds) =>
      set({
        domainIds: newIds,
      }),
    setLanguageIds: (newIds) =>
      set({
        languageIds: newIds,
      }),
    setTechnologiesIds: (newIds) =>
      set({
        technologyIds: newIds,
      }),
    setFiltersIds: (ids) =>
      set({
        ...ids,
      }),

    clearSkillsFilters: () =>
      set({
        languageIds: [],
        technologyIds: [],
      }),
    clearDomainsFilters: () =>
      set({
        domainIds: [],
      }),

    clearFilters: () =>
      set({
        domainIds: [],
        technologyIds: [],
        languageIds: [],
      }),
  }))
)
