import React from 'react'
import { useRoutes } from 'react-router-dom'

import { PUBLIC_ROUTES } from './routes'

export const PublicRoutes: React.FC = () => {
  const publicRoutes = useRoutes(PUBLIC_ROUTES)

  return publicRoutes
}
