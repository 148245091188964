import { gql } from '@apollo/client'

export const FRAGMENT_RESUME_LANGUAGE_FULL = gql`
  fragment FragmentResumeLanguageFull on ResumeLanguage {
    id
    publicName
    icon
    experience
  }
`
