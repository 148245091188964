import React from 'react'
import { Link } from 'react-router-dom'

import { TextButton } from './TextButton'
import { EnumTextButtonVariants, ITextButtonProps } from './types'

export const TextButtonLink = (
  props: Omit<ITextButtonProps<typeof Link>, 'as'>
) => {
  return <TextButton as={Link} {...props} />
}

TextButtonLink.Variant = EnumTextButtonVariants
