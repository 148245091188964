import { Language } from 'graphql/types'

export type IBadge = Pick<Language, 'icon' | 'id' | 'publicName'>

export const getBadgesWithOrder = (
  badges: Array<IBadge>,
  highlightedIdsMap: Record<string, boolean>
) => {
  const firstOrderBadges: Array<IBadge> = []
  const secondOrderBadges: Array<IBadge> = []

  badges.forEach((badge) =>
    highlightedIdsMap[badge.id]
      ? firstOrderBadges.push(badge)
      : secondOrderBadges.push(badge)
  )

  return firstOrderBadges.concat(secondOrderBadges)
}

export const getBadgesToRender = (
  badges: Array<IBadge>,
  rowWidth: number,
  highlightedIdsMap: Record<string, boolean>
) => {
  const badgesWithOrder = getBadgesWithOrder(badges, highlightedIdsMap)
  const badgesToReturn: Array<IBadge> = []

  const calculateBadgeWidth = (badge: IBadge) => {
    let width = badge.publicName.length * 8.5

    if (badge.icon) {
      width += 26
    }

    const tagPadding = 16

    return width + tagPadding
  }

  let spareRow = true
  let currentRowWidth = rowWidth

  for (const badge of badgesWithOrder) {
    const badgeWidth = calculateBadgeWidth(badge)
    const isCurrentRowHasSpace = currentRowWidth - badgeWidth >= 0

    if (isCurrentRowHasSpace) {
      currentRowWidth -= badgeWidth
      badgesToReturn.push(badge)
    } else {
      if (spareRow) {
        const endTagWidth = 44

        spareRow = false
        currentRowWidth = rowWidth - endTagWidth
      } else {
        badgesToReturn.push({
          id: 'custom_badge',
          publicName: `+${badges.length - badgesToReturn.length}`,
        })

        return badgesToReturn
      }
    }
  }

  return badgesToReturn
}
