import React from 'react'

import clsx from 'clsx'

import {
  Button,
  EnumButtonVariants,
  EnumBreakpoints,
  useMediaQuery,
  useToggle,
} from '@insquad/tools'

import { BurgerMenu } from 'common/components/BurgerMenu/BurgerMenu'
import { SidebarWrapper } from 'common/components/Sidebar'
import { UserType } from 'graphql/types'

import { ReactComponent as LogoIcon } from './logoIcon.svg'
import { ResultHeaderMobileMenu } from './ResultHeaderMobileMenu/ResultHeaderMobileMenu'

import s from './CandidateShareResultHeader.module.scss'

export interface ICandidateShareResultHeaderProps {
  onSignUp: (userType: UserType) => () => void
}

export const CandidateShareResultHeader: React.FC<
  ICandidateShareResultHeaderProps
> = ({ onSignUp }) => {
  const menuToggler = useToggle()
  const isMobile = useMediaQuery(EnumBreakpoints.BREAKPOINT_MD, 'max')

  const btns = (
    <div className={s.CandidateShareResultHeader__btns}>
      <Button
        onClick={onSignUp(UserType.Candidate)}
        className={clsx(
          s.CandidateShareResultHeader__btn,
          s.CandidateShareResultHeader__btnSecondary
        )}
        variant={EnumButtonVariants.SECONDARY}
      >
        Apply for a job
      </Button>

      <Button
        onClick={onSignUp(UserType.Company)}
        className={s.CandidateShareResultHeader__btn}
      >
        Hire developers
      </Button>
    </div>
  )

  return (
    <header className={s.CandidateShareResultHeader}>
      <div className={s.CandidateShareResultHeader__inner}>
        <BurgerMenu
          isOpen={menuToggler.value}
          onClick={menuToggler.toggle}
          className={s.CandidateShareResultHeader__menu}
          classes={{
            btnClass: s.CandidateShareResultHeader__menuBtn,
          }}
        />

        <div className={s.CandidateShareResultHeader__logo}>
          <LogoIcon />
        </div>

        {isMobile ? (
          <ResultHeaderMobileMenu isOpen={menuToggler.value}>
            {btns}
          </ResultHeaderMobileMenu>
        ) : (
          btns
        )}
      </div>
    </header>
  )
}
