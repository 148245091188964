import { hotjar } from 'react-hotjar'
import { BrowserRouter } from 'react-router-dom'

import SwiperCore, { Mousewheel } from 'swiper'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ReactDOM from 'react-dom/client'

import { AuthProvider } from 'common/components/_utilsComponents/AuthProvider/AuthProvider'
import { ErrorBoundary } from 'common/components/_utilsComponents/ErrorBoundary/ErrorBoundary'
import { apolloClient } from 'graphql/client'
import {
  REACT_APP_HOTJAR_HJID,
  REACT_APP_HOTJAR_HJSV,
  REACT_APP_SENTRY_DSN,
} from 'utils/config'
import 'common/hooks/useI18n/useI18n'

import { App } from './App'

SwiperCore.use([Mousewheel])

hotjar.initialize(
  parseInt(REACT_APP_HOTJAR_HJID, 10),
  parseInt(REACT_APP_HOTJAR_HJSV, 10)
)

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: process.env.STAGE,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
})

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container as HTMLElement)

root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </BrowserRouter>
)
