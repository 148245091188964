import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
  EnumBreakpoints,
  useMediaQuery,
} from '@insquad/tools'

import { ReactComponent as UsersIcon } from 'assets/icons/simple/usersIcon.svg'
import { ImgPreview } from 'common/components/ImgPreview/ImgPreview'
import { Maybe } from 'graphql/types'

import s from './CandidateResumeInfoEmpty.module.scss'

export interface ICandidateResumeInfoEmptyProps {
  firstName: Maybe<string> | undefined
  lastName: Maybe<string> | undefined
}

export const CandidateResumeInfoEmpty: React.FC<
  ICandidateResumeInfoEmptyProps
> = ({ firstName, lastName }) => {
  const isDesktop = useMediaQuery(EnumBreakpoints.BREAKPOINT_MD, 'min')

  return (
    <section className={s.CandidateResumeInfoEmpty}>
      {isDesktop && (
        <ImgPreview
          placeholderElement={<UsersIcon />}
          className={s.CandidateResumeInfoEmpty__avatar}
        />
      )}

      <div className={s.CandidateResumeInfoEmpty__content}>
        <div className={s.CandidateResumeInfoEmpty__titleWrapper}>
          {!isDesktop && (
            <ImgPreview
              placeholderElement={<UsersIcon />}
              className={s.CandidateResumeInfoEmpty__avatar}
            />
          )}

          <Typography
            variant={EnumTypographyVariants.H2}
            className={s.CandidateResumeInfoEmpty__title}
            text={`${firstName || ''} ${lastName || ''}`}
          />
        </div>

        <div
          className={clsx(s.CandidateResumeInfoEmpty__emptyBlock, {
            [s.CandidateResumeInfoEmpty__emptyBlock_mobile]: !isDesktop,
          })}
        >
          <Typography
            variant={EnumTypographyVariants.H2}
            text="Detailed information about your experience and skills will appear here after you pass the interview with your Career Advisor"
            className={s.CandidateResumeInfoEmpty__emptyText}
          />
        </div>
      </div>
    </section>
  )
}
