import React from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { ScrollTopOnRouteChange } from 'common/components/_utilsComponents/ScrollTopOnRouteChange/ScrollTopOnRouteChange'

import { PrivateRoutesWrapper } from './routes/private'
import { PublicRoutes } from './routes/public'

const APP_ROUTES: RouteObject[] = [
  {
    path: 'public/*',
    element: <PublicRoutes />,
  },
  {
    path: '*',
    element: <PrivateRoutesWrapper />,
  },
]

export const App: React.FC = () => {
  const appRoutes = useRoutes(APP_ROUTES)

  return (
    <>
      {appRoutes}

      <ScrollTopOnRouteChange />
      <ToastContainer
        autoClose={5000}
        closeOnClick={false}
        draggable={false}
        position="top-right"
        closeButton={false}
        limit={3}
        hideProgressBar
        newestOnTop
      />
    </>
  )
}

// TODO @storybook/addon-knobs, @storybook/addon-actions

// TODO переделать структуру иконок. Возможно стоит написать один wrapper <Icon />. Далее перенести все svg в формат .tsx

// TODO разобраться почему сломались тесты, починить

// TODO все что выполняет роль кнопки - должно быть кнопкой

// TODO подумать, как можно упроситит написание gql
