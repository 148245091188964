import React from 'react'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { ReactComponent as ClipboardIcon } from 'assets/icons/simple/clipboardIcon.svg'
import { Maybe } from 'graphql/types'

import { getHTMLFromResumeText } from '../_utils'
import { CandidateResumeSection } from '../CandidateResumeSection/CandidateResumeSection'

import s from './CandidateResumeProjectExpectations.module.scss'

export interface ICandidateResumeProjectExpectationsProps {
  text: Maybe<string> | undefined
}

export const CandidateResumeProjectExpectations: React.FC<
  ICandidateResumeProjectExpectationsProps
> = ({ text }) => {
  if (!text) {
    return null
  }

  return (
    <CandidateResumeSection
      icon={<ClipboardIcon />}
      title="Project expectations"
    >
      <Typography
        variant={EnumTypographyVariants.P2}
        className={s.CandidateResumeProjectExpectations__description}
        dangerouslySetInnerHTML={{
          __html: getHTMLFromResumeText(text),
        }}
      />
    </CandidateResumeSection>
  )
}
