import { onError } from '@apollo/client/link/error'
import { EnumToastVariants } from '@insquad/tools'

import { openToast } from 'common/components/Toast/Toast'

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  // FIX добавить обработку в сентри
  if (graphQLErrors) {
    ;(graphQLErrors as any[]).forEach(({ errorType, message, reason }) => {
      console.log(
        `[GraphQL error]: ErrorType: ${errorType}, Message: ${message}, Reason: ${reason}`
      )

      if (errorType === 'AuthorizationError') {
        // TODO это точно работает?
        localStorage.clear()
      } else {
        openToast({
          text: 'Something went wrong...',
          variant: EnumToastVariants.ERROR,
        })
      }
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)

    openToast({
      text: 'Internet connection not stable...',
      variant: EnumToastVariants.ERROR,
    })
  }
})
