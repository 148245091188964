import React from 'react'

import clsx from 'clsx'

import { ReactComponent as UserIcon } from 'assets/icons/simple/userIcon.svg'
import {
  IImgPreviewProps,
  ImgPreview,
} from 'common/components/ImgPreview/ImgPreview'

import s from './Avatar.module.scss'

export interface IAvatarProps extends IImgPreviewProps {
  width?: number
  height?: number
}

export const Avatar: React.FC<IAvatarProps> = ({
  width = 48,
  height,
  className,
  ...otherProps
}) => {
  const avatarStyle: React.CSSProperties = {
    width,
    height: height || width,
    minWidth: width,
  }

  return (
    <ImgPreview
      {...otherProps}
      style={{ ...otherProps.style, ...avatarStyle }}
      placeholderElement={<UserIcon />}
      className={clsx(s.Avatar, className)}
      classes={{
        ...otherProps.classes,
        rootLoading: s.Avatar_default,
        rootFailed: s.Avatar_default,
      }}
    />
  )
}
