import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
  IModalProps,
  Modal,
  IToggler,
  useToggle,
} from '@insquad/tools'

import { ReactComponent as AwardIcon } from 'assets/icons/simple/awardIcon.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/simple/helpIcon.svg'
import {
  EnumTextButtonVariants,
  TextButton,
} from 'common/components/TextButton'
import { Maybe, SkillStepType, VettedSkill } from 'graphql/types'

import { CandidateResumeSection } from '../CandidateResumeSection/CandidateResumeSection'

import { VettedSkillCard } from './VettedSkillCard/VettedSkillCard'

import s from './CandidateResumeVettedSkills.module.scss'

export interface ICandidateResumeVettedSkillsProps {
  vettedSkills: Maybe<VettedSkill[]> | undefined

  children?: React.ReactNode
  modalBodyPortalParent?: IModalProps['modalBodyPortalParent']
  modalBlackoutPortalParent?: IModalProps['modalBodyPortalParent']
  areCodingResultsHidden?: boolean | undefined
  hiddenResultsTooltipContent?: string
  cardClassName?: string
  isBorderHidden?: boolean
  isHintHidden?: boolean
  isTitleHidden?: boolean
  isSectionHidden?: boolean
  hintModalToggler?: IToggler

  onCardHeaderClick?: () => void
  onCardWatchResultClick?: (stepType: SkillStepType) => void
}

export const CandidateResumeVettedSkills: React.FC<
  ICandidateResumeVettedSkillsProps
> = ({
  vettedSkills,
  modalBlackoutPortalParent,
  modalBodyPortalParent,
  areCodingResultsHidden,
  cardClassName,
  isBorderHidden,
  isHintHidden,
  isSectionHidden,
  children,
  hintModalToggler,
  isTitleHidden,
  hiddenResultsTooltipContent,
  onCardHeaderClick,
  onCardWatchResultClick,
}) => {
  const toggler = useToggle()
  const modalToggler = hintModalToggler || toggler

  if (!vettedSkills?.length && !children) {
    return null
  }

  const renderContent = () => (
    <>
      {!!vettedSkills?.length && (
        <>
          {!isTitleHidden && (
            <Typography
              variant={EnumTypographyVariants.P2}
              className={clsx(s.CandidateResumeVettedSkills__title, {
                [s.CandidateResumeVettedSkills__title_margin]: isHintHidden,
              })}
              text="This developer passed 3+ hours of rigorous tests for the following skills:"
            />
          )}

          {!isHintHidden && (
            <TextButton
              className={s.CandidateResumeVettedSkills__description}
              variant={EnumTextButtonVariants.SECONDARY}
              contentVariant={EnumTypographyVariants.P2}
              iconLeft={
                <HelpIcon className={s.CandidateResumeVettedSkills__icon} />
              }
              onClick={modalToggler.set}
            >
              What is a Vetted skill? Learn more about our skill assessment
              methodology
            </TextButton>
          )}

          {vettedSkills.map((skill, i) => (
            <VettedSkillCard
              key={i}
              index={i}
              className={clsx(
                s.CandidateResumeVettedSkills__card,
                cardClassName
              )}
              isButtonsDisabled={areCodingResultsHidden}
              onCardHeaderClick={onCardHeaderClick}
              onCardWatchResultClick={onCardWatchResultClick}
              disabledButtonsTooltipContent={hiddenResultsTooltipContent}
              {...skill}
            />
          ))}

          <Modal
            title="What is a Vetted skill?"
            isOpen={modalToggler.value}
            onClose={modalToggler.unset}
            modalBodyPortalParent={modalBodyPortalParent}
            blackoutPortalParent={modalBlackoutPortalParent}
          >
            <Typography
              variant={EnumTypographyVariants.P2}
              text="To become an Insquad developer, candidates take a test on technologies which they are planning to use in their work. The test has 2 parts:"
            />

            <ul className={s.CandidateResumeVettedSkills__modalList}>
              <li>
                Quiz part (15 questions). In this part Insquad Vetting System
                will assess the candidate&apos;s theoretical knowledge. If the
                candidate passes the quiz, they can proceed to the next part.
              </li>
              <li>
                Algorhythmical problem solving (2 tasks). Here we assess
                candidate&apos;s practical skills in coding. The candidate
                writes the code, and Insquad Vetting System evaluates how
                effective the code is.
              </li>
            </ul>

            <Typography
              variant={EnumTypographyVariants.P2}
              text="In the section below you can watch the candidate answer the questions and also review the code the candidate wrote to solve the tasks."
            />
          </Modal>
        </>
      )}

      {children}
    </>
  )

  return isSectionHidden ? (
    renderContent()
  ) : (
    <CandidateResumeSection
      title="Vetted Skills"
      icon={<AwardIcon />}
      isBorderHidden={isBorderHidden}
    >
      {renderContent()}
    </CandidateResumeSection>
  )
}
