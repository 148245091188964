import { gql } from '@apollo/client'

import { FRAGMENT_RESUME_SHORTENED } from './resumeFragment'

export const FRAGMENT_SALES_OFFER = gql`
  fragment FragmentSalesOffer on SalesOffer {
    id
    companyName
    technology
    ShortResumes {
      ...FragmentResumeShortened
    }
  }

  ${FRAGMENT_RESUME_SHORTENED}
`
