import React from 'react'
import { useTransition, animated } from 'react-spring'

import { useDisableOverflow } from '@insquad/tools'

import s from './ResultHeaderMobileMenu.module.scss'

export interface IResultHeaderMobileMenuProps {
  isOpen: boolean
  children: React.ReactNode
}

export const ResultHeaderMobileMenu: React.FC<IResultHeaderMobileMenuProps> = ({
  isOpen,
  children,
}) => {
  const slideTransition = useTransition(isOpen, {
    from: {
      transform: 'translateY(100%)',
    },
    enter: {
      transform: 'translateY(0%)',
    },
    leave: {
      transform: 'translateY(100%)',
    },
  })

  useDisableOverflow(isOpen)

  return slideTransition(
    (style, isVisible) =>
      isVisible && (
        <animated.div style={style} className={s.ResultHeaderMobileMenu}>
          {children}
        </animated.div>
      )
  )
}
