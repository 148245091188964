import { gql } from '@apollo/client'

import { FRAGMENT_INTERVIEWER_FULL } from './interviewerFragment'
import { FRAGMENT_LANGUAGE_FULL } from './languageFragment'
import { FRAGMENT_RESUME_LANGUAGE_FULL } from './resumeLanguageFragment'
import { FRAGMENT_RESUME_TECHNOLOGY_FULL } from './resumeTechnologyFragment'
import { FRAGMENT_TECHNOLOGY_FULL } from './technologyFragment'
import { FRAGMENT_TIMECODE_FULL } from './timecodeFragment'
import { FRAGMENT_VETTED_SKILL } from './vettedSkillFragment'

export const FRAGMENT_RESUME_FULL = gql`
  fragment FragmentResumeFull on Resume {
    id
    firstName
    lastName
    photo
    specialization
    totalExperience
    summary
    keySkills
    projectExpectations
    videoLink
    invitedToCallFlag
    overlap
    englishLevel
    githubLink
    price
    Timecodes {
      ...FragmentTimecodeFull
    }
    Technologies {
      ...FragmentResumeTechnologyFull
    }
    Languages {
      ...FragmentResumeLanguageFull
    }
    WorkHistory {
      companyName
      position
      startDate
      endDate
      description
      aboutCompany
      responsibilities
      Technologies {
        ...FragmentTechnologyFull
      }
      Languages {
        ...FragmentLanguageFull
      }
      Domains {
        id
        publicName
      }
    }
    Projects {
      companyName
      position
      startDate
      endDate
      description
      Technologies {
        ...FragmentTechnologyFull
      }
      Languages {
        ...FragmentLanguageFull
      }
    }
    Education {
      university
      specialization
      startDate
      endDate
    }
    VettedSkills {
      ...FragmentVettedSkill
    }
    Interviewer {
      ...FragmentInterviewerFull
    }
  }

  ${FRAGMENT_TECHNOLOGY_FULL}
  ${FRAGMENT_LANGUAGE_FULL}
  ${FRAGMENT_VETTED_SKILL}
  ${FRAGMENT_INTERVIEWER_FULL}
  ${FRAGMENT_RESUME_LANGUAGE_FULL}
  ${FRAGMENT_RESUME_TECHNOLOGY_FULL}
  ${FRAGMENT_TIMECODE_FULL}
`

export const FRAGMENT_RESUME_SHORTENED = gql`
  fragment FragmentResumeShortened on ShortResume {
    id
    firstName
    lastName
    photo
    specialization
    totalExperience
    summary
    overlap
    Languages {
      ...FragmentLanguageFull
    }
    Technologies {
      ...FragmentTechnologyFull
    }
  }

  ${FRAGMENT_TECHNOLOGY_FULL}
  ${FRAGMENT_LANGUAGE_FULL}
`

export const FRAGMENT_RESUME_FULL_WITH_PHONE = gql`
  fragment FragmentResumeFullWithPhone on Resume {
    ...FragmentResumeFull
    email
    phone
  }

  ${FRAGMENT_RESUME_FULL}
`

export const FRAGMENT_RESUME_RESULT = gql`
  fragment FragmentResumeResult on Resume {
    firstName
    lastName
    VettedSkills {
      ...FragmentVettedSkill
    }
  }

  ${FRAGMENT_VETTED_SKILL}
`
