import React from 'react'

import clsx from 'clsx'

import { useToggle } from '@insquad/tools'

import { SkillStepType, VettedSkill } from 'graphql/types'

import { CardContent } from './_components/CardContent/CardContent'
import { CardHeader } from './_components/CardHeader/CardHeader'

import s from './VettedSkillCard.module.scss'

export interface IVettedSkillCardProps extends VettedSkill {
  className: string
  index: number

  isButtonsDisabled: boolean | undefined
  disabledButtonsTooltipContent?: string

  onCardHeaderClick?: () => void
  onCardWatchResultClick?: (stepType: SkillStepType) => void
}

export const VettedSkillCard: React.FC<IVettedSkillCardProps> = ({
  isButtonsDisabled,
  publicName,
  score,
  steps,
  icon,
  index,
  className,
  disabledButtonsTooltipContent,
  onCardWatchResultClick,
  onCardHeaderClick,
}) => {
  const contentToggler = useToggle(index === 0)

  const handleCardHeaderToggle = () => {
    contentToggler.toggle()
    onCardHeaderClick?.()
  }

  return (
    <article className={clsx(s.VettedSkillCard, className)}>
      <CardHeader
        icon={icon}
        isOpen={contentToggler.value}
        onToggle={handleCardHeaderToggle}
        score={score}
        title={publicName}
      />

      <CardContent
        isButtonsDisabled={isButtonsDisabled}
        isOpen={contentToggler.value}
        steps={steps}
        onClickWatchResult={onCardWatchResultClick}
        disabledButtonsTooltipContent={disabledButtonsTooltipContent}
      />
    </article>
  )
}
