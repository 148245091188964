import { gql } from '@apollo/client'

export const FRAGMENT_TECHNOLOGY_FULL = gql`
  fragment FragmentTechnologyFull on Technology {
    id
    publicName
    icon
    experience
  }
`
