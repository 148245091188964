import React, { useEffect } from 'react'
import { useSpring } from 'react-spring'

import { Blackout, Spinner } from '@insquad/tools'

import s from './FirstLoadingLayout.module.scss'

export interface IFirstLoadingLayoutProps {}

export const FirstLoadingLayout: React.FC<IFirstLoadingLayoutProps> = () => {
  const { opacity } = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 240 },
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      opacity.start()
    }, 300)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Blackout animatedStyle={{ opacity }}>
      <div className={s.FirstLoadingLayout__spinner}>
        <Spinner size="lg" />
      </div>
    </Blackout>
  )
}
