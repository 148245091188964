import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import clsx from 'clsx'
import screenfull from 'screenfull'

import {
  EnumTypographyVariants,
  Typography,
  Spinner,
  EnumBreakpoints,
  useMediaQuery,
  useMount,
} from '@insquad/tools'

import { Maybe, Timecode } from 'graphql/types'

import { ReactComponent as FullscreenIcon } from './maximizeIcon.svg'
import { ReactComponent as WindowScreenIcon } from './minimizeIcon.svg'
import { ReactComponent as MutedIcon } from './mutedIcon.svg'
import { ReactComponent as PauseIcon } from './pauseIcon.svg'
import { ReactComponent as OnPlayIcon } from './playIcon.svg'
import { VideoSeekSlider } from './VideoSeekSlider/VideoSeekSlider'
import { ReactComponent as VolumeIcon } from './volumeIcon.svg'

import s from './VideoPlayer.module.scss'

const rateList = [2.5, 2, 1.5, 1]

export interface IVideoPlayerProps {
  videoLink: string
  timecodes?: Maybe<Timecode[]>
}

export const VideoPlayer: React.FC<IVideoPlayerProps> = ({
  videoLink,
  timecodes,
}) => {
  const playerContainerRef = useRef(null)
  const playerRef = useRef<ReactPlayer>(null)
  const [player, setPlayer] = useState({
    isPlaying: true,
    volume: 0.8,
    played: 0,
    loaded: 0,
    playedSeconds: 0,
    loadedSeconds: 0,
    duration: 0,
    isMuted: false,
    isFullscreen: false,
    playbackRate: 1,
  })

  const [isLoading, setIsLoading] = useState(true)
  const [isOpenSelectRate, setIsOpenSelectRate] = useState(false)
  const isMobile = useMediaQuery(EnumBreakpoints.BREAKPOINT_SM, 'max')
  const isTablet = useMediaQuery(EnumBreakpoints.BREAKPOINT_LG, 'max')

  const getFullTimecodes = (timecodes: Timecode[]) => {
    return timecodes[0]?.time !== 0
      ? [{ id: '1', time: 0, title: 'Intro' }, ...timecodes]
      : timecodes
  }

  const fullTimecodes = getFullTimecodes(timecodes || [])

  useMount(() => {
    window.addEventListener('keydown', handleKeyboardControls)
    return () => {
      window.removeEventListener('keydown', handleKeyboardControls)
    }
  })

  const handlePlay = () => {
    setPlayer((player) => ({ ...player, isPlaying: !player.isPlaying }))
  }
  const handleProgress = (state: {
    played: number
    playedSeconds: number
    loaded: number
    loadedSeconds: number
  }) => {
    setPlayer({
      ...player,
      playedSeconds: state.playedSeconds,
      loadedSeconds: state.loadedSeconds,
      played: state.played,
      loaded: state.loaded,
    })
  }

  const handleVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlayer((player) => ({
      ...player,
      volume: Number(e.target.value),
    }))
  }

  const handlePlaybackRate = (rate: number) => {
    setPlayer((player) => ({
      ...player,
      playbackRate: rate,
    }))
  }

  const handleTimeChange = (time: number) => {
    if (playerRef.current) {
      playerRef?.current?.seekTo(time / 1000)

      setPlayer((player) => ({
        ...player,
        playedSeconds: time / 1000,
      }))
    }
  }

  const getTime = (time: number): string => {
    const h = Math.floor(time / 3600)
    const m = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, '0')
    const s = Math.round((time % 3600) % 60)
      .toString()
      .padStart(2, '0')

    if (h) {
      return `${h}:${m}:${s}`
    }

    return `${m}:${s}`
  }

  const handleToggleFullScreen = () => {
    setPlayer((player) => ({
      ...player,
      isFullscreen: !player.isFullscreen,
    }))
    screenfull.toggle(playerContainerRef.current || undefined)
  }

  const handleKeyboardControls = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'Space':
        handlePlay()
        break
      case 'KeyM':
        setPlayer((player) => ({ ...player, isMuted: !player.isMuted }))
        break
      case 'KeyF':
        handleToggleFullScreen()
        break
      case 'ArrowUp':
        setPlayer((player) => ({
          ...player,
          volume: player.volume > 0.95 ? 1 : player.volume + 0.05,
        }))
        break
      case 'ArrowDown':
        setPlayer((player) => ({
          ...player,
          volume: player.volume < 0.05 ? 0 : player.volume - 0.05,
        }))
        break
      case 'ArrowRight':
        setPlayer((player) => ({
          ...player,
          playedSeconds: player.playedSeconds + 5,
        }))
        if (playerRef.current) {
          playerRef?.current?.seekTo(playerRef.current.getCurrentTime() + 5)
        }
        break

      case 'ArrowLeft':
        setPlayer((player) => ({
          ...player,
          playedSeconds: player.playedSeconds - 5,
        }))
        if (playerRef.current) {
          playerRef?.current?.seekTo(playerRef.current.getCurrentTime() - 5)
        }
        break
    }
  }

  return (
    <div ref={playerContainerRef} className={s.VideoPlayer__player}>
      <ReactPlayer
        ref={playerRef}
        url={videoLink}
        allow="autoplay; encrypted-media"
        width="100%"
        height="100%"
        playing={player.isPlaying}
        volume={player.volume}
        muted={player.isMuted}
        controls={isMobile}
        onProgress={handleProgress}
        onDuration={(duration) => {
          setPlayer({ ...player, duration })
        }}
        onEnded={() => setPlayer({ ...player, isPlaying: false })}
        onReady={() => {
          setIsLoading(false)
          setPlayer({ ...player, isPlaying: true })
        }}
        playbackRate={player.playbackRate}
      />
      {!isMobile && (
        <div className={s.VideoPlayer__controls}>
          <div className={s.VideoPlayer__block}>
            <div className={s.VideoPlayer__play}>
              <div className={s.VideoPlayer__icon}>
                {player.isPlaying ? (
                  <PauseIcon onClick={handlePlay} />
                ) : (
                  <OnPlayIcon onClick={handlePlay} />
                )}
              </div>

              <Typography
                variant={
                  isMobile
                    ? EnumTypographyVariants.CAPTION
                    : EnumTypographyVariants.P2
                }
                text={`${getTime(player.playedSeconds)}/${getTime(
                  player.duration
                )}`}
                className={s.VideoPlayer__text}
              />
              <Typography
                variant={
                  isMobile
                    ? EnumTypographyVariants.CAPTION
                    : EnumTypographyVariants.P2
                }
                text={
                  !!timecodes?.length
                    ? fullTimecodes
                        ?.filter(
                          (timecode: Timecode) =>
                            player.playedSeconds > timecode.time
                        )
                        .reverse()[0]?.title || ''
                    : ''
                }
                className={s.VideoPlayer__text}
              />
            </div>
            <div className={s.VideoPlayer__play}>
              {!isTablet && (
                <>
                  {!player.isMuted ? (
                    <div className={s.VideoPlayer__volume}>
                      <div className={s.VideoPlayer__icon_small}>
                        <VolumeIcon
                          onClick={() =>
                            setPlayer({ ...player, isMuted: !player.isMuted })
                          }
                        />
                      </div>

                      <input
                        className={s.VideoPlayer__volume_range}
                        type="range"
                        value={player.volume}
                        min={0}
                        max={1}
                        step={0.01}
                        onChange={handleVolume}
                      />
                    </div>
                  ) : (
                    <div className={s.VideoPlayer__icon_small}>
                      <MutedIcon
                        onClick={() =>
                          setPlayer({ ...player, isMuted: !player.isMuted })
                        }
                      />
                    </div>
                  )}
                </>
              )}

              <div
                className={s.VideoPlayer__icon_small}
                onClick={handleToggleFullScreen}
              >
                {player.isFullscreen ? (
                  <WindowScreenIcon />
                ) : (
                  <FullscreenIcon />
                )}
              </div>

              <div
                className={s.VideoPlayer__text}
                onClick={() => setIsOpenSelectRate(!isOpenSelectRate)}
              >
                <Typography
                  variant={
                    isMobile
                      ? EnumTypographyVariants.CAPTION
                      : EnumTypographyVariants.P2
                  }
                  text={`x${player.playbackRate}`}
                  className={s.VideoPlayer__text_hover}
                />
                <ul
                  className={clsx(s.VideoPlayer__rate, {
                    [s.VideoPlayer__rate_visible]: isOpenSelectRate,
                  })}
                >
                  {rateList.map((rate, i) => (
                    <li key={i}>
                      <Typography
                        variant={
                          isMobile
                            ? EnumTypographyVariants.CAPTION
                            : EnumTypographyVariants.P2
                        }
                        className={s.VideoPlayer__text_hover}
                        text={`x${rate}`}
                        onClick={() => handlePlaybackRate(rate)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={s.VideoPlayer__timeline}>
            {!!timecodes?.length ? (
              <VideoSeekSlider
                max={player.duration * 1000}
                currentTime={player.playedSeconds * 1000}
                bufferTime={player.loadedSeconds * 1000}
                onChange={handleTimeChange}
                limitTimeTooltipBySides
                secondsPrefix="00:00:"
                minutesPrefix="00:"
                timeCodes={fullTimecodes.map((timecode: Timecode) => {
                  return {
                    fromMs: timecode.time * 1000,
                    description: timecode.title,
                  }
                })}
                isFullScreen={player.isFullscreen}
              />
            ) : (
              <VideoSeekSlider
                max={player.duration * 1000}
                currentTime={player.playedSeconds * 1000}
                bufferTime={player.loadedSeconds * 1000}
                onChange={handleTimeChange}
                limitTimeTooltipBySides
                secondsPrefix="00:00:"
                minutesPrefix="00:"
                isFullScreen={player.isFullscreen}
              />
            )}
          </div>
        </div>
      )}
      <div
        className={clsx(s.VideoPlayer__spinner, {
          [s.VideoPlayer__spinner_visible]: isLoading,
        })}
      >
        <Spinner size="xl" />
      </div>
    </div>
  )
}
