import { gql } from '@apollo/client'

export const FRAGMENT_LANGUAGE_FULL = gql`
  fragment FragmentLanguageFull on Language {
    id
    publicName
    icon
    experience
  }
`
