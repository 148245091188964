import React from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { Avatar } from 'common/components/Avatar'
import { Maybe } from 'graphql/types'

import s from './CandidateCardHeader.module.scss'

export interface ICandidateCardHeaderProps {
  name: Maybe<string>
  isBlured: boolean
  role?: Maybe<string>
  avatarSrc?: Maybe<string>
}

export const CandidateCardHeader: React.FC<ICandidateCardHeaderProps> = ({
  name,
  role,
  avatarSrc,
  isBlured,
}) => {
  return (
    <div className={s.CandidateCardHeader}>
      <Avatar imageSrc={avatarSrc} className={s.CandidateCardHeader__avatar} />

      <div>
        {name && (
          <Typography
            variant={EnumTypographyVariants.P1}
            className={clsx(s.CandidateCardHeader__name, {
              [s.CandidateCardHeader__blured]: isBlured,
            })}
            text={name}
          />
        )}

        {role && (
          <Typography
            variant={EnumTypographyVariants.P3}
            className={s.CandidateCardHeader__role}
            text={role}
          />
        )}
      </div>
    </div>
  )
}
