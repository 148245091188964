import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client'

import { IGetCompanyResponse, QUERY_GET_COMPANY } from '../gql/getCompany'

export const useCompanyQueryLazy = (
  options?: QueryHookOptions<IGetCompanyResponse, void>
) => {
  return useLazyQuery<IGetCompanyResponse, void>(QUERY_GET_COMPANY, options)
}

export const useCompanyQuery = (
  options?: QueryHookOptions<IGetCompanyResponse, void>
) => {
  return useQuery<IGetCompanyResponse, void>(QUERY_GET_COMPANY, options)
}

export const useCompanyInfo = () => {
  const { data } = useCompanyQuery()

  return {
    name: data?.result.name ?? '',
    id: data?.result.id ?? '',
    isQualified: !!data?.result.isQualified,
    inviteDemoUrl: data?.result.inviteDemoUrl ?? '',
    interviewScheduledAt: data?.result.InterviewSession?.scheduledAt ?? '',
  }
}
