import React from 'react'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { CandidateResumeVettedSkills } from 'common/components/CandidateResume'
import { Resume, UserType } from 'graphql/types'
import { normalizeString } from 'utils/string'

import { ShareBanner } from './ShareBanner/ShareBanner'

import s from './CandidateShareContent.module.scss'

export interface ICandidateShareContentProps {
  resume: Pick<Resume, 'firstName' | 'lastName' | 'VettedSkills'>
  skillId: string
  onSignUp: (userType: UserType) => () => void
}

export const CandidateShareContent: React.FC<ICandidateShareContentProps> = ({
  resume,
  skillId,
  onSignUp,
}) => {
  const { firstName, lastName, VettedSkills } = resume

  const nameString = `${firstName} ${lastName}`.trim()
  const title = `${nameString}'s Skill Assessment Results`
  const skillToShow = VettedSkills?.filter(
    ({ name }) => normalizeString(name) === normalizeString(skillId)
  )

  return (
    <>
      <div className={s.CandidateShareContent__inner}>
        <Typography
          className={s.CandidateShareContent__title}
          variant={EnumTypographyVariants.H1}
          text={title}
        />

        <CandidateResumeVettedSkills
          vettedSkills={skillToShow}
          cardClassName={s.CandidateShareContent__vettedSkillCard}
          areCodingResultsHidden
          isTitleHidden
          isSectionHidden
        />
      </div>

      <ShareBanner onSignUp={onSignUp} />
    </>
  )
}
