import React from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { ReactComponent as ClockIcon } from 'assets/icons/simple/clockIcon.svg'
import { Education } from 'graphql/types'

import { getDurationPeriodsString, getDurationString } from '../../_utils'

import s from './EducationCard.module.scss'

export interface IEducationCardProps extends Education {
  className?: string
}

export const EducationCard: React.FC<IEducationCardProps> = ({
  specialization,
  startDate,
  university,
  endDate,
  className,
}) => {
  const duration = getDurationString(startDate, endDate)
  const durationDates = getDurationPeriodsString(startDate, endDate)

  return (
    <article className={clsx(s.EducationCard, className)}>
      <div className={s.EducationCard__inner}>
        <div className={s.EducationCard__circle} />

        <div className={s.EducationCard__line} />

        <Typography
          variant={EnumTypographyVariants.P1}
          mobileVariant={EnumTypographyVariants.P2}
          text={specialization}
          className={s.EducationCard__title}
        />
        <Typography
          variant={EnumTypographyVariants.P3}
          text={university}
          className={s.EducationCard__description}
        />

        {(!!duration || !!durationDates) && (
          <Typography
            variant={EnumTypographyVariants.P3}
            mobileVariant={EnumTypographyVariants.CAPTION}
            className={s.EducationCard__period}
            as="div"
          >
            <div className={s.EducationCard__icon}>
              <ClockIcon />
            </div>

            {duration && (
              <>
                {duration}

                <span className={s.EducationCard__verticalLine}>&#124;</span>
              </>
            )}

            {durationDates}
          </Typography>
        )}
      </div>
    </article>
  )
}
