import React from 'react'

import { ReactComponent as BriefCaseIcon } from 'assets/icons/simple/briefcaseIcon.svg'
import { Maybe, Work } from 'graphql/types'
import { useCompanyStoreSkillsFilters } from 'routes/private/company/pages/CompanyCandidatesPage/_store/useCompanyStoreSkillsFilters/useCompanyStoreSkillsFilters'

import { CandidateResumeSection } from '../CandidateResumeSection/CandidateResumeSection'

import { WorkExperienceCard } from './WorkExperienceCard/WorkExperienceCard'

export interface ICandidateResumeWorkExperienceProps {
  workExperience: Maybe<Work[]> | undefined
}

export const CandidateResumeWorkExperience: React.FC<
  ICandidateResumeWorkExperienceProps
> = ({ workExperience }) => {
  const { languageIds, technologyIds } = useCompanyStoreSkillsFilters()
  const selectedFilters = [...languageIds, ...technologyIds]
  if (!workExperience?.length) {
    return null
  }

  return (
    <CandidateResumeSection title="Work Experience" icon={<BriefCaseIcon />}>
      {workExperience.map((work, i) => (
        <WorkExperienceCard
          key={i}
          {...work}
          selectedFilters={selectedFilters}
        />
      ))}
    </CandidateResumeSection>
  )
}
