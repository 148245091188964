import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Spinner, EnumToastVariants } from '@insquad/tools'

import { openToast } from 'common/components/Toast/Toast'
import { NotFoundLayout } from 'common/layouts/NotFoundLayout/NotFoundLayout'
import { useResumeResultQuery } from 'graphql/queries/resume/hooks/useResumeQuery'
import { UserType } from 'graphql/types'

import { CandidateShareContent } from './CandidateShareContent/CandidateShareContent'
import { CandidateShareResultHeader } from './CandidateShareResultHeader/CandidateShareResultHeader'

import s from './CandidateShareResultPage.module.scss'

export interface ICandidateShareResultPageProps {}

export const CandidateShareResultPage: React.FC<
  ICandidateShareResultPageProps
> = () => {
  const [searchParams] = useSearchParams()
  const { loginWithRedirect } = useAuth0()

  const skillId = searchParams.get('skillId')
  const resumeId = searchParams.get('resumeId')

  const { data: resumeData, error } = useResumeResultQuery({
    variables: {
      id: resumeId || '',
    },
    onError() {
      openToast({
        text: 'Failed to load skill',
        variant: EnumToastVariants.ERROR,
      })
    },
  })

  const handleSignUp = (userType: UserType) => {
    return () => {
      loginWithRedirect({
        redirectUri: window.origin + `?role=${userType.toLocaleLowerCase()}`,
      })
    }
  }

  if (!skillId || !resumeId || error) {
    return <NotFoundLayout />
  }

  return (
    <main className={s.CandidateShareResultPage}>
      <CandidateShareResultHeader onSignUp={handleSignUp} />
      <section className={s.CandidateShareResultPage__content}>
        {resumeData?.result ? (
          <CandidateShareContent
            resume={resumeData.result}
            skillId={skillId}
            onSignUp={handleSignUp}
          />
        ) : (
          <div className={s.CandidateShareResultPage__loading}>
            <Spinner size="xl" />
          </div>
        )}
      </section>
    </main>
  )
}
