import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography, EnumTypographyVariants } from '@insquad/tools'

import { ReactComponent as CheckIcon } from 'assets/icons/simple/checkIcon.svg'
import DevelopersCollagePng from 'assets/images/developersCollageSmall.png'
import { ShortResume } from 'graphql/types'
import { CompanyCandidateCard } from 'routes/private/company/pages/CompanyCandidatesPage/CompanyCandidatesList/CompanyCandidateCard/CompanyCandidateCard'

import s from './SalesLandingContent.module.scss'

interface ISalesLandingContent {
  technology: string
  resumes: Array<ShortResume>
}

const SalesLandingContent: React.FC<ISalesLandingContent> = ({
  technology,
  resumes,
}) => {
  const navigate = useNavigate()

  const handleClickResume = (id: string) => {
    navigate(`/public/developer-profile/${id}`)
  }

  return (
    <main className={s.SalesLandingContent}>
      <Typography className={s.SalesLandingContent__title} as="div">
        Here are our TOP {resumes.length || '3'}{' '}
        <span className={s.SalesLandingContent__titleTechnology}>
          {technology || 'Technology'}
        </span>{' '}
        developers
      </Typography>
      <div className={s.SalesLandingContent__candidatesList}>
        {!!resumes &&
          resumes.map((r) => (
            <CompanyCandidateCard
              key={r.id}
              {...r}
              firstName={`${r.firstName} ${r.lastName}`}
              isPerfectMatch
              selectedTechnologiesIds={[]}
              selectedLanguagesIds={[]}
              isBlured={false}
              onSelectResume={handleClickResume}
            />
          ))}
      </div>
      <div className={s.SalesLandingContent__banner}>
        <Typography
          variant={EnumTypographyVariants.H2}
          as={'div'}
          className={s.SalesLandingContent__bannerList}
        >
          Сonnect to our representative to get access to the entire database of
          perfect matched candidates
          <Typography
            variant={EnumTypographyVariants.P1}
            className={s.SalesLandingContent__bannerListItem}
          >
            <CheckIcon className={s.SalesLandingContent__bannerListIcon} />
            Rigorous hard and soft skills vetting
          </Typography>
          <Typography
            variant={EnumTypographyVariants.P1}
            className={s.SalesLandingContent__bannerListItem}
          >
            <CheckIcon className={s.SalesLandingContent__bannerListIcon} />
            World’s top talent in under two weeks
          </Typography>
          <Typography
            variant={EnumTypographyVariants.P1}
            className={s.SalesLandingContent__bannerListItem}
          >
            <CheckIcon className={s.SalesLandingContent__bannerListIcon} />
            Save 50 hours of teamwork on each hire
          </Typography>
        </Typography>
        <img
          src={DevelopersCollagePng}
          alt=""
          className={s.SalesLandingContent__bannerImg}
        />
      </div>
    </main>
  )
}

export default SalesLandingContent
