import React from 'react'

import { Typography, EnumTypographyVariants } from '@insquad/tools'

import { ReactComponent as CloseBigIcon } from 'assets/icons/simple/closeBigIcon.svg'
import { HeaderContainer } from 'common/components/HeaderContainer/HeaderContainer'
import { Logo } from 'common/components/Logo/Logo'

import s from './SalesLandingHeader.module.scss'

interface ISalesLandingHeaderProps {
  title: string
}

const SalesLandingHeader: React.FC<ISalesLandingHeaderProps> = ({ title }) => {
  return (
    <HeaderContainer
      className={s.SalesLandingHeader}
      innerClassName={s.SalesLandingHeader__inner}
    >
      <div className={s.SalesLandingHeader__logoWrapper}>
        <Logo />

        <div>
          <CloseBigIcon />
        </div>

        <Typography variant={EnumTypographyVariants.H2} text={title || ''} />
      </div>
    </HeaderContainer>
  )
}

export default SalesLandingHeader
