import { gql } from '@apollo/client'

export const FRAGMENT_RESUME_TECHNOLOGY_FULL = gql`
  fragment FragmentResumeTechnologyFull on ResumeTechnology {
    id
    publicName
    icon
    experience
  }
`
