export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** ask-question */
export type AskQuestion = {
  __typename?: 'AskQuestion';
  candidateLoginGuid: Scalars['String'];
  companyUserLoginGuid: Scalars['String'];
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

/** book-interview */
export type BookInterview = {
  __typename?: 'BookInterview';
  candidateLoginGuid: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  companyUserLoginGuid: Scalars['String'];
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
};

/** candidate */
export type Candidate = {
  __typename?: 'Candidate';
  CandidateSkills: Array<CandidateSkill>;
  DemoInterviewSession?: Maybe<DemoInterviewSession>;
  InterviewSession?: Maybe<InterviewSession>;
  SelfInterviewSession?: Maybe<SelfInterviewSession>;
  experience?: Maybe<Experience>;
  id: Scalars['ID'];
  interviewBookUrl?: Maybe<Scalars['String']>;
  isReadyForInterview: Scalars['Boolean'];
  locale?: Maybe<Scalars['String']>;
  moderationComment?: Maybe<Scalars['String']>;
  noticePeriod?: Maybe<Scalars['Float']>;
  status: Status;
  uploadedCv: Scalars['Boolean'];
};

export type CandidateAddSkillInput = {
  skillName: Scalars['String'];
};

export type CandidateCvLinkInput = {
  link: Scalars['String'];
};

/** candidate-skill */
export type CandidateSkill = {
  __typename?: 'CandidateSkill';
  CandidateSkillSteps: Array<CandidateSkillStep>;
  createdAt: Scalars['DateTime'];
  icon?: Maybe<Scalars['String']>;
  publicName: Scalars['String'];
  skillName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** skill-step */
export type CandidateSkillStep = {
  __typename?: 'CandidateSkillStep';
  blocked: Scalars['Boolean'];
  duration?: Maybe<Scalars['Float']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  passed?: Maybe<Scalars['Boolean']>;
  requireScore: Scalars['Float'];
  retryAfter?: Maybe<Scalars['Float']>;
  scorePercent?: Maybe<Scalars['Float']>;
  sessionSkillGuid?: Maybe<Scalars['String']>;
  skillName: Scalars['String'];
  stepNumber: Scalars['Float'];
  stepType: SkillStepType;
  templateId: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  versionNumber: Scalars['Float'];
};

export type CandidateUpdateProfileInput = {
  experience: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  highPriority?: InputMaybe<Scalars['Boolean']>;
  joinedFrom?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  noticePeriod?: InputMaybe<Scalars['Float']>;
  phone: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  utmCampaign?: InputMaybe<Scalars['String']>;
};

/** company */
export type Company = {
  __typename?: 'Company';
  InterviewSession?: Maybe<InterviewSession>;
  id: Scalars['ID'];
  inviteDemoUrl?: Maybe<Scalars['String']>;
  isQualified: Scalars['Boolean'];
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  status: CompanyUserStatus;
  technologyListIds?: Maybe<Scalars['String']>;
};

export type CompanyUpdateProfileInput = {
  companyName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isQualified?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  position?: InputMaybe<Scalars['String']>;
  technologyListIds?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export enum CompanyUserStatus {
  DemoMissed = 'DEMO_MISSED',
  DemoPassed = 'DEMO_PASSED',
  DemoScheduled = 'DEMO_SCHEDULED',
  Intro = 'INTRO',
  Spam = 'SPAM'
}

export type CreateSkillSessionInput = {
  skillName: Scalars['String'];
  stepNumber: Scalars['Float'];
};

/** demo-interview-session */
export type DemoInterviewSession = {
  __typename?: 'DemoInterviewSession';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  shortUrl?: Maybe<Scalars['String']>;
  status: DemoInterviewStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export enum DemoInterviewStatus {
  Create = 'CREATE',
  Finish = 'FINISH',
  Start = 'START'
}

/** domain */
export type Domain = {
  __typename?: 'Domain';
  id: Scalars['ID'];
  publicName: Scalars['String'];
};

/** education */
export type Education = {
  __typename?: 'Education';
  endDate?: Maybe<Scalars['String']>;
  specialization: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  university: Scalars['String'];
};

export enum Experience {
  FiveEight = 'FIVE_EIGHT',
  LessYear = 'LESS_YEAR',
  MoreEight = 'MORE_EIGHT',
  OneTwo = 'ONE_TWO',
  ThreeFive = 'THREE_FIVE',
  TwoThree = 'TWO_THREE'
}

/** interview-session */
export type InterviewSession = {
  __typename?: 'InterviewSession';
  Interviewer?: Maybe<Interviewer>;
  cancelUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  joinUrl?: Maybe<Scalars['String']>;
  rescheduleUrl?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** interviewer */
export type Interviewer = {
  __typename?: 'Interviewer';
  avatar?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** language */
export type Language = {
  __typename?: 'Language';
  experience?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  publicName: Scalars['String'];
};

/** login */
export type Login = {
  __typename?: 'Login';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  joinedFrom?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userType: UserType;
  username: Scalars['String'];
};

export type LoginBookedCallInput = {
  interviewEventUrl: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addSkillToCandidate: Candidate;
  addVacancyToCandidate: Array<Vacancy>;
  askQuestion: AskQuestion;
  bookedCall: Login;
  companyBookInterview: BookInterview;
  createDemoInterview: Candidate;
  createSelfInterview: Candidate;
  createSkillSession: SkillSession;
  removeSkillFromCandidate: Candidate;
  saveCvLink: Candidate;
  updateCandidateProfile: Candidate;
  updateCompanyProfile: Company;
  uploadCvFile: Candidate;
};


export type MutationAddSkillToCandidateArgs = {
  input: CandidateAddSkillInput;
};


export type MutationAddVacancyToCandidateArgs = {
  id: Scalars['String'];
};


export type MutationAskQuestionArgs = {
  input: ResumeAskQuestionInput;
};


export type MutationBookedCallArgs = {
  input: LoginBookedCallInput;
};


export type MutationCompanyBookInterviewArgs = {
  input: ResumeBookInterviewInput;
};


export type MutationCreateSkillSessionArgs = {
  input: CreateSkillSessionInput;
};


export type MutationRemoveSkillFromCandidateArgs = {
  skillName: Scalars['String'];
};


export type MutationSaveCvLinkArgs = {
  input: CandidateCvLinkInput;
};


export type MutationUpdateCandidateProfileArgs = {
  input: CandidateUpdateProfileInput;
};


export type MutationUpdateCompanyProfileArgs = {
  input: CompanyUpdateProfileInput;
};


export type MutationUploadCvFileArgs = {
  file: Scalars['Upload'];
};

export type PaginatedShortResume = {
  __typename?: 'PaginatedShortResume';
  edges?: Maybe<Array<ShortResumeEdge>>;
  hasNextPage: Scalars['Boolean'];
  nodes?: Maybe<Array<ShortResume>>;
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  getAllResumes: PaginatedShortResume;
  getAvailableSkills: Array<Skill>;
  getCandidate: Candidate;
  getCompany: Company;
  getLogin: Login;
  getResume: Resume;
  getResumeFilters: ResumeFilters;
  getSalesOffer: SalesOffer;
  getTimezones: Array<Timezone>;
  getVacancies: Array<Vacancy>;
};


export type QueryGetAllResumesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  domainIds?: InputMaybe<Array<Scalars['String']>>;
  languageIds?: InputMaybe<Array<Scalars['String']>>;
  maxPrice?: InputMaybe<Scalars['Float']>;
  maxUtc?: InputMaybe<Scalars['Float']>;
  minPrice?: InputMaybe<Scalars['Float']>;
  minUtc?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  technologyIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetResumeArgs = {
  id: Scalars['ID'];
  sharedByCandidate?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetSalesOfferArgs = {
  slug: Scalars['String'];
};

/** candidate-resume */
export type Resume = {
  __typename?: 'Resume';
  Education?: Maybe<Array<Education>>;
  Interviewer?: Maybe<Interviewer>;
  Languages?: Maybe<Array<ResumeLanguage>>;
  Projects?: Maybe<Array<Work>>;
  Technologies?: Maybe<Array<ResumeTechnology>>;
  Timecodes?: Maybe<Array<Timecode>>;
  VettedSkills?: Maybe<Array<VettedSkill>>;
  WorkHistory?: Maybe<Array<Work>>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  englishLevel?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  githubLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invitedToCallFlag: Scalars['Boolean'];
  keySkills?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  overlap?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  projectExpectations?: Maybe<Scalars['String']>;
  specialization?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
};

export type ResumeAskQuestionInput = {
  candidateLoginGuid: Scalars['String'];
  question?: InputMaybe<Scalars['String']>;
};

export type ResumeBookInterviewInput = {
  candidateLoginGuid: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  duration: Scalars['Float'];
  timezone: Scalars['String'];
};

/** resume-filters */
export type ResumeFilters = {
  __typename?: 'ResumeFilters';
  Domains?: Maybe<Array<Domain>>;
  Languages?: Maybe<Array<Language>>;
  Technologies?: Maybe<Array<Technology>>;
};

/** resume-language */
export type ResumeLanguage = {
  __typename?: 'ResumeLanguage';
  experience?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  publicName: Scalars['String'];
};

/** resume-technology */
export type ResumeTechnology = {
  __typename?: 'ResumeTechnology';
  experience?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  publicName: Scalars['String'];
};

/** sales-offer */
export type SalesOffer = {
  __typename?: 'SalesOffer';
  ShortResumes?: Maybe<Array<ShortResume>>;
  companyName: Scalars['String'];
  id: Scalars['ID'];
  technology?: Maybe<Scalars['String']>;
};

/** self-interview-session */
export type SelfInterviewSession = {
  __typename?: 'SelfInterviewSession';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  shortUrl?: Maybe<Scalars['String']>;
  status: SelfInterviewStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export enum SelfInterviewStatus {
  Create = 'CREATE',
  Finish = 'FINISH',
  Start = 'START'
}

/** candidate-short-resume */
export type ShortResume = {
  __typename?: 'ShortResume';
  Languages?: Maybe<Array<Language>>;
  Technologies?: Maybe<Array<Technology>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  overlap?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  specialization?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['String']>;
};

export type ShortResumeEdge = {
  __typename?: 'ShortResumeEdge';
  cursor: Scalars['String'];
  node: ShortResume;
};

/** skill */
export type Skill = {
  __typename?: 'Skill';
  SkillSteps: Array<SkillStep>;
  createdAt: Scalars['DateTime'];
  icon?: Maybe<Scalars['String']>;
  isOptional: Scalars['Boolean'];
  name: Scalars['String'];
  publicName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** skill-session */
export type SkillSession = {
  __typename?: 'SkillSession';
  cancelledAt?: Maybe<Scalars['DateTime']>;
  cancelledReason?: Maybe<SkillSessionCancelReason>;
  createdAt: Scalars['DateTime'];
  duration?: Maybe<Scalars['Float']>;
  externalId: Scalars['String'];
  feedback?: Maybe<Scalars['JSON']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  guid: Scalars['ID'];
  loginGuid: Scalars['String'];
  passed: Scalars['Boolean'];
  score?: Maybe<Scalars['JSON']>;
  scorePercent?: Maybe<Scalars['Float']>;
  skillName: Scalars['String'];
  startedAt?: Maybe<Scalars['DateTime']>;
  status: SkillSessionStatus;
  stepNumber: Scalars['Float'];
  tasks?: Maybe<Scalars['JSON']>;
  template?: Maybe<Scalars['JSON']>;
  templateId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  versionNumber: Scalars['Float'];
};

export enum SkillSessionCancelReason {
  NoTasks = 'NO_TASKS',
  Timeout = 'TIMEOUT'
}

export enum SkillSessionStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Finished = 'FINISHED',
  Inprogress = 'INPROGRESS',
  Onpause = 'ONPAUSE',
  Started = 'STARTED'
}

/** skill-step */
export type SkillStep = {
  __typename?: 'SkillStep';
  createdAt: Scalars['DateTime'];
  requireScore: Scalars['Float'];
  retryAfter: Scalars['Float'];
  skillName: Scalars['String'];
  stepNumber: Scalars['Float'];
  stepType: SkillStepType;
  templateId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionNumber: Scalars['Float'];
};

/** skill-step-feedback */
export type SkillStepFeedback = {
  __typename?: 'SkillStepFeedback';
  feedback?: Maybe<Scalars['JSON']>;
  sessionSkillGuid?: Maybe<Scalars['String']>;
  skillName: Scalars['String'];
  stepNumber: Scalars['Float'];
  templateId: Scalars['String'];
  versionNumber: Scalars['Float'];
};

/** skill-step-score */
export type SkillStepScore = {
  __typename?: 'SkillStepScore';
  duration?: Maybe<Scalars['Float']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  passed?: Maybe<Scalars['Boolean']>;
  requireScore: Scalars['Float'];
  retryAfter?: Maybe<Scalars['Float']>;
  scorePercent?: Maybe<Scalars['Float']>;
  sessionSkillGuid?: Maybe<Scalars['String']>;
  skillName: Scalars['String'];
  stepNumber: Scalars['Float'];
  stepType: SkillStepType;
  templateId: Scalars['String'];
  versionNumber: Scalars['Float'];
};

/** skill-step-task */
export type SkillStepTask = {
  __typename?: 'SkillStepTask';
  maxScore: Scalars['Float'];
  score: Scalars['Float'];
  tags?: Maybe<Array<SkillStepTaskTag>>;
};

/** skill-step-task-tag */
export type SkillStepTaskTag = {
  __typename?: 'SkillStepTaskTag';
  name: Scalars['String'];
};

export enum SkillStepType {
  CodeReview = 'CODE_REVIEW',
  Coding = 'CODING',
  Database = 'DATABASE',
  Quiz = 'QUIZ'
}

export enum Status {
  CvUploaded = 'CV_UPLOADED',
  CvUploadAwaiting = 'CV_UPLOAD_AWAITING',
  HrCanceled = 'HR_CANCELED',
  HrConfirmationAwaiting = 'HR_CONFIRMATION_AWAITING',
  HrFailed = 'HR_FAILED',
  HrMissed = 'HR_MISSED',
  HrPassed = 'HR_PASSED',
  HrScheduled = 'HR_SCHEDULED',
  Intro = 'INTRO',
  ModerationFailed = 'MODERATION_FAILED',
  OfferAccepted = 'OFFER_ACCEPTED',
  OfferAwaiting = 'OFFER_AWAITING',
  OfferDeclined = 'OFFER_DECLINED',
  ProfileCompleted = 'PROFILE_COMPLETED',
  SelfInterviewCompleted = 'SELF_INTERVIEW_COMPLETED',
  SelfInterviewFailed = 'SELF_INTERVIEW_FAILED',
  SelfInterviewStarted = 'SELF_INTERVIEW_STARTED',
  SkillSelected = 'SKILL_SELECTED',
  SkillStepCanceled = 'SKILL_STEP_CANCELED',
  SkillStepFailed = 'SKILL_STEP_FAILED',
  SkillStepPassed = 'SKILL_STEP_PASSED',
  SkillStepScoreAwaiting = 'SKILL_STEP_SCORE_AWAITING',
  SkillStepStarted = 'SKILL_STEP_STARTED'
}

export type Subscription = {
  __typename?: 'Subscription';
  scheduledDemoInterview: InterviewSession;
  scheduledInterview: InterviewSession;
  sendFeedback: SkillStepFeedback;
  sendSkillStepScore: SkillStepScore;
};


export type SubscriptionScheduledDemoInterviewArgs = {
  loginGuid: Scalars['String'];
};


export type SubscriptionScheduledInterviewArgs = {
  loginGuid: Scalars['String'];
};


export type SubscriptionSendFeedbackArgs = {
  sessionSkillGuid: Scalars['String'];
};


export type SubscriptionSendSkillStepScoreArgs = {
  sessionSkillGuid: Scalars['String'];
};

/** technology */
export type Technology = {
  __typename?: 'Technology';
  experience?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  publicName: Scalars['String'];
};

/** timecode */
export type Timecode = {
  __typename?: 'Timecode';
  id: Scalars['ID'];
  time: Scalars['Float'];
  title: Scalars['String'];
};

/** timezone */
export type Timezone = {
  __typename?: 'Timezone';
  cities?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  offset: Scalars['Float'];
  timezone: Scalars['ID'];
  utc: Scalars['String'];
};

export enum UserType {
  Admin = 'ADMIN',
  Candidate = 'CANDIDATE',
  Company = 'COMPANY'
}

/** vacancy */
export type Vacancy = {
  __typename?: 'Vacancy';
  Languages?: Maybe<Array<Language>>;
  Technologies?: Maybe<Array<Technology>>;
  active: Scalars['Boolean'];
  applied: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  employees?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  salary?: Maybe<Scalars['String']>;
  specification?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleRu?: Maybe<Scalars['String']>;
  descriptionRu?: Maybe<Scalars['String']>;
};

/** vetted-skill */
export type VettedSkill = {
  __typename?: 'VettedSkill';
  icon?: Maybe<Scalars['String']>;
  isOptional: Scalars['Boolean'];
  name: Scalars['ID'];
  publicName: Scalars['String'];
  score: Scalars['Float'];
  steps: Array<VettedSkillStep>;
};

/** vetted-skill-step */
export type VettedSkillStep = {
  __typename?: 'VettedSkillStep';
  averageTime?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  netTime?: Maybe<Scalars['Float']>;
  passed?: Maybe<Scalars['Boolean']>;
  reportUrl?: Maybe<Scalars['String']>;
  scorePercent?: Maybe<Scalars['Float']>;
  skillName: Scalars['String'];
  status: Scalars['String'];
  stepNumber: Scalars['Float'];
  stepType: SkillStepType;
  tasks: Array<SkillStepTask>;
  templateId: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  versionNumber: Scalars['Float'];
};

/** work */
export type Work = {
  __typename?: 'Work';
  Domains?: Maybe<Array<Domain>>;
  Languages?: Maybe<Array<Language>>;
  Technologies?: Maybe<Array<Technology>>;
  aboutCompany?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  responsibilities?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};
