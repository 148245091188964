import React from 'react'

import { EnumTypographyVariants } from '@insquad/tools'

export const TextButtonDefaultAsType = 'button' as const
export type TextButtonDefaultAsType = typeof TextButtonDefaultAsType

export enum EnumTextButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface ITextButtonClasses {
  iconLeft?: string
  iconRight?: string
  content?: string
  root?: string
}

export interface ITextButtonOwnProps<E extends React.ElementType> {
  className?: string
  children?: React.ReactNode
  variant?: EnumTextButtonVariants
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  classes?: ITextButtonClasses
  contentVariant?: EnumTypographyVariants
  innerRef?: React.ComponentProps<E>['ref']
  isLoading?: boolean
  as?: E
}

export type ITextButtonProps<E extends React.ElementType> =
  ITextButtonOwnProps<E> &
    Omit<React.ComponentProps<E>, keyof ITextButtonOwnProps<E>>
