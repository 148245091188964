import { useRef } from 'react'

import { useGetLoginQuery } from 'graphql/queries/login/hooks/useGetLoginQuery'
import { UserType } from 'graphql/types'
import {
  REACT_APP_ANALYTIC_COMPANY_KEY,
  REACT_APP_ANALYTIC_KEY,
} from 'utils/config'

import { CandidateAnalytic } from './CandidateAnalytic'
import { CompanyAnalytic } from './CompanyAnalytic'
import { IAnalytic } from './types'

let analytic: IAnalytic | null = null

export const useAnalytic = (): { analytic: IAnalytic } => {
  const { data: loginData } = useGetLoginQuery()

  const getAnalyticInstance = (): IAnalytic => {
    if (analytic) {
      return analytic
    }

    if (loginData?.result.userType === UserType.Candidate) {
      analytic = new CandidateAnalytic(REACT_APP_ANALYTIC_KEY)

      return analytic
    }

    analytic = new CompanyAnalytic(REACT_APP_ANALYTIC_COMPANY_KEY)

    return analytic
  }

  const classRef = useRef(getAnalyticInstance())

  return {
    analytic: classRef.current,
  }
}
