import React from 'react'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { ReactComponent as ArchiveIcon } from 'assets/icons/simple/archiveIcon.svg'
import {
  ResumeLanguage,
  Maybe,
  ResumeTechnology,
  Work,
  VettedSkill,
} from 'graphql/types'

import { getHasExperienceIn } from '../_utils'
import { CandidateResumeSection } from '../CandidateResumeSection/CandidateResumeSection'

import s from './CandidateResumeOtherExperience.module.scss'

export interface ICandidateResumeOtherExperienceProps {
  technologies: Maybe<Array<ResumeTechnology>> | undefined
  languages: Maybe<Array<ResumeLanguage>> | undefined
  work: Maybe<Array<Work>> | undefined
  vettedSkills: Maybe<VettedSkill[]> | undefined
}

export const CandidateResumeOtherExperience: React.FC<
  ICandidateResumeOtherExperienceProps
> = ({ technologies, languages, work, vettedSkills }) => {
  const otherExperience = getHasExperienceIn({
    languages: languages || [],
    technologies: technologies || [],
    workArray: work || [],
    vettedSkills: vettedSkills || [],
  })

  const getIconUrl = (name: string) => {
    let iconUrl
    for (const w of work || []) {
      w?.Languages?.forEach(({ publicName, icon }) => {
        if (publicName === name) {
          iconUrl = icon
        }
      })
      w?.Technologies?.forEach(({ publicName, icon }) => {
        if (publicName === name) {
          iconUrl = icon
        }
      })
    }

    return iconUrl
  }

  if (!otherExperience.length) {
    return null
  }

  return (
    <CandidateResumeSection title="Other experience" icon={<ArchiveIcon />}>
      <Typography
        variant={EnumTypographyVariants.P2}
        className={s.CandidateResumeOtherExperience__description}
        text="The candidate is also acquainted with these technologies"
      />

      <div className={s.CandidateResumeOtherExperience__experiences}>
        {otherExperience.map((t, i) => (
          <Typography
            key={i}
            className={s.CandidateResumeOtherExperience__experience}
            variant={EnumTypographyVariants.P2}
            as="div"
          >
            {getIconUrl(t) && (
              <img
                src={getIconUrl(t) || ''}
                className={s.CandidateResumeOtherExperience__icon}
                alt=""
              />
            )}
            {t}
          </Typography>
        ))}
      </div>
    </CandidateResumeSection>
  )
}
