import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { GraphQLError } from 'graphql'

import { useToggle } from '@insquad/tools'

import { CandidateResume } from 'common/components/CandidateResume'
import { EnumCompanyRoutes } from 'common/enums/routesEnums'
import { FirstLoadingLayout } from 'common/layouts/FirstLoadingLayout/FirstLoadingLayout'
import { NotFoundLayout } from 'common/layouts/NotFoundLayout/NotFoundLayout'
import { useGetLoginQuery } from 'graphql/queries/login/hooks/useGetLoginQuery'
import { useResumeWithPhoneQuery } from 'graphql/queries/resume/hooks/useResumeQuery'
import { UserType } from 'graphql/types'

import { ResumeContainer } from '../CandidateResumePage/ResumeContainer/ResumeContainer'

import { ResumeContacts } from './ResumeContacts/ResumeContacts'
import { ResumeLoginBanner } from './ResumeLoginBanner/ResumeLoginBanner'

export interface ICandidateShareResumePageProps {}

export const CandidateShareResumePage: React.FC<
  ICandidateShareResumePageProps
> = () => {
  const navigate = useNavigate()
  const { id = '' } = useParams()

  const notFoundToggler = useToggle()

  const { loading: isLoadingLogin } = useGetLoginQuery({
    onCompleted({ result }) {
      if (result.userType === UserType.Company) {
        navigate(EnumCompanyRoutes.COMPANY_CANDIDATES_ROUTE)
      }
    },
  })

  const {
    data: resumeData,
    loading,
    error,
  } = useResumeWithPhoneQuery({
    variables: { id, sharedByCandidate: true },
    onError({ graphQLErrors }) {
      const isNotFound = (
        graphQLErrors as (GraphQLError & { statusCode: number })[]
      ).find(({ statusCode }) => statusCode === 404)

      isNotFound && notFoundToggler.set()
    },
  })

  if (notFoundToggler.value) {
    return <NotFoundLayout />
  }

  if (loading || !resumeData || error || isLoadingLogin) {
    return <FirstLoadingLayout />
  }

  return (
    <ResumeContainer
      resumeId={id}
      VettedSkills={resumeData.result.VettedSkills}
    >
      {({ handleVettedSkillsHeaderClick, handleVettedSkillsResultClick }) => (
        <>
          <ResumeContacts
            email={resumeData.result.email}
            phone={resumeData.result.phone}
          />

          <CandidateResume
            resume={resumeData.result}
            sectionsProps={{
              sectionVettedSkillsProps: {
                onCardHeaderClick: handleVettedSkillsHeaderClick,
                onCardWatchResultClick: handleVettedSkillsResultClick,
              },
              sectionInfoProps: {
                isSalaryHidden: true,
              },
            }}
          />

          <ResumeLoginBanner />
        </>
      )}
    </ResumeContainer>
  )
}
