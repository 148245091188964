import TagManager from 'react-gtm-module'

import amplitude from 'amplitude-js'

import { EnumAnalyticCandidateEvent } from 'common/enums/analyticEventEnum'
import { EnumAnalyticCandidateProperty } from 'common/enums/analyticPropertyEnum'
import { REACT_APP_GTM } from 'utils/config'

import { IAnalytic, ICandidateAnalyticProperty } from './types'

export class CandidateAnalytic implements IAnalytic {
  private loginGuid = ''

  private selectedSkills: string[] = []
  private provedCodingSkills: string[] = []
  private provedQuizSkills: string[] = []

  private excludedEventsTypes = [
    EnumAnalyticCandidateEvent.FAIL_QUIZ,
    EnumAnalyticCandidateEvent.FAIL_CODING,
  ]

  constructor(key: string) {
    TagManager.initialize({
      gtmId: REACT_APP_GTM,
    })

    amplitude.getInstance().init(key, undefined, {
      deviceIdFromUrlParam: true,
      saveEvents: true,
      includeUtm: true,
      includeGclid: true,
      includeReferrer: true,
    })

    amplitude.getInstance().logEvent('session_start')
  }

  setUserId(loginGuid: string) {
    amplitude.getInstance().setUserId(loginGuid)
    this.loginGuid = loginGuid
  }

  userProperty({ key, value }: ICandidateAnalyticProperty) {
    if (key === EnumAnalyticCandidateProperty.SELECTED_SKILLS) {
      this.selectedSkills = value as string[]
    }

    if (key === EnumAnalyticCandidateProperty.PROVED_QUIZ_SKILLS) {
      this.provedQuizSkills = value as string[]
    }

    if (key === EnumAnalyticCandidateProperty.PROVED_CODING_SKILLS) {
      this.provedCodingSkills = value as string[]
    }

    const identify = new amplitude.Identify().set(key, value)
    amplitude.getInstance().identify(identify)
  }

  userPropertyOnce({ key, value }: ICandidateAnalyticProperty) {
    const identify = new amplitude.Identify().setOnce(key, value)
    amplitude.getInstance().identify(identify)
  }

  event(
    event: EnumAnalyticCandidateEvent,
    properties?: Record<string, string>
  ) {
    if (
      event === EnumAnalyticCandidateEvent.SELECT_SKILL_CLICK &&
      properties?.hasOwnProperty('skillName')
    ) {
      this.selectedSkills.push(properties.skillName)
      this.userProperty({
        key: EnumAnalyticCandidateProperty.SELECTED_SKILLS,
        value: this.selectedSkills,
      })
    }

    if (
      event === EnumAnalyticCandidateEvent.PASS_QUIZ &&
      properties?.hasOwnProperty('skillName')
    ) {
      this.provedQuizSkills.push(properties.skillName)
      this.userProperty({
        key: EnumAnalyticCandidateProperty.PROVED_QUIZ_SKILLS,
        value: this.provedQuizSkills,
      })
    }

    if (
      event === EnumAnalyticCandidateEvent.PASS_CODING &&
      properties?.hasOwnProperty('skillName')
    ) {
      this.provedCodingSkills.push(properties.skillName)
      this.userProperty({
        key: EnumAnalyticCandidateProperty.PROVED_CODING_SKILLS,
        value: this.provedCodingSkills,
      })
    }

    const isEventNotExcluded = !this.excludedEventsTypes.find(
      (eventType) => eventType === event
    )

    if (isEventNotExcluded) {
      if (
        event === EnumAnalyticCandidateEvent.SELECT_SKILL_CLICK ||
        event === EnumAnalyticCandidateEvent.PASS_QUIZ ||
        event === EnumAnalyticCandidateEvent.PASS_CODING
      ) {
        setTimeout(() => {
          amplitude.getInstance().logEvent(event, properties)
        }, 2000)
      } else {
        amplitude.getInstance().logEvent(event, properties)
      }
    }

    window.dataLayer.push({
      event,
      properties,
      userId: this.loginGuid,
    })
  }
}
