import { gql } from '@apollo/client'

export const FRAGMENT_SELF_INTERVIEW_SESSION = gql`
  fragment FragmentSelfInterviewSession on SelfInterviewSession {
    id
    shortUrl
    status
    updatedAt
    url
  }
`
