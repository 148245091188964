import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
  Button,
  ButtonAnchor,
  EnumButtonVariants,
  IModalProps,
  Modal,
  IToggler,
  useToggle,
} from '@insquad/tools'

import { ReactComponent as PlayIcon } from 'assets/icons/simple/playIcon.svg'
import { ReactComponent as VideoIcon } from 'assets/icons/simple/videoIcon.svg'
import { VideoPlayer } from 'common/components/VideoPlayer/VideoPlayer'
import { EnumAnalyticCompanyEvent } from 'common/enums/analyticEventEnum'
import { Interviewer, Maybe, Timecode } from 'graphql/types'
import { useAnalytic } from 'utils/analytic/useAnalytic'

import { CandidateResumeSection } from '../CandidateResumeSection/CandidateResumeSection'

import { InterviewerCard } from './InterviewerCard/InterviewerCard'

import s from './CandidateResumeVideo.module.scss'

export interface ICandidateResumeVideoProps {
  isVideoViewDisabled?: boolean
  videoClassName?: string
  modalBodyPortalParent?: IModalProps['modalBodyPortalParent']
  modalBlackoutPortalParent?: IModalProps['modalBodyPortalParent']
  hintModalToggler?: IToggler
  firstName?: Maybe<string>
  videoLink?: Maybe<string>
  interviewer?: Maybe<Interviewer>
  photo?: Maybe<string>
  timecodes?: Maybe<Timecode[]>
}

export const CandidateResumeVideo: React.FC<ICandidateResumeVideoProps> = ({
  videoLink,
  interviewer,
  photo,
  isVideoViewDisabled,
  videoClassName,
  modalBlackoutPortalParent,
  modalBodyPortalParent,
  hintModalToggler,
  timecodes,
  firstName,
}) => {
  const { analytic } = useAnalytic()

  const toggler = useToggle()
  const modalToggler = hintModalToggler || toggler

  if (!videoLink) {
    return null
  }

  const watchInterviewHandler = () => {
    analytic.event(EnumAnalyticCompanyEvent.VIDEO_INTERVIEW_CLICK)
    modalToggler.set()
  }

  return (
    <>
      <CandidateResumeSection
        title="Video Interview"
        icon={<VideoIcon />}
        contentClassName={s.CandidateResumeVideo}
      >
        <div
          className={clsx(s.CandidateResumeVideo__videoPreview, videoClassName)}
        >
          {!!photo && <img src={photo} alt="" />}

          <div className={s.CandidateResumeVideo__videoOverlay}>
            {isVideoViewDisabled ? (
              <Button
                variant={EnumButtonVariants.SECONDARY}
                className={s.CandidateResumeVideo__btn}
                iconLeft={<PlayIcon />}
                disabled
              >
                Watch interview
              </Button>
            ) : (
              <ButtonAnchor
                variant={EnumButtonVariants.SECONDARY}
                className={s.CandidateResumeVideo__btn}
                iconLeft={<PlayIcon />}
                // target="_blank"
                // href={videoLink}
                onClick={watchInterviewHandler}
              >
                Watch interview
              </ButtonAnchor>
            )}
          </div>
        </div>

        {interviewer && <InterviewerCard {...interviewer} />}
      </CandidateResumeSection>

      <Modal
        isOpen={modalToggler.value}
        onClose={modalToggler.unset}
        modalBodyPortalParent={modalBodyPortalParent}
        blackoutPortalParent={modalBlackoutPortalParent}
        classes={{
          bodyClassName: s.CandidateResumeVideo__modalBody,
          contentClassName: s.CandidateResumeVideo__modalContent,
        }}
      >
        <VideoPlayer videoLink={videoLink} timecodes={timecodes} />
        <Typography
          variant={EnumTypographyVariants.H3}
          text={firstName ? `${firstName}'s interview` : ''}
          className={s.CandidateResumeVideo__text}
        />
      </Modal>
    </>
  )
}
