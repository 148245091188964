import React, { useEffect } from 'react'

import {
  EnumAnalyticCandidateEvent,
  EnumAnalyticCompanyEvent,
} from 'common/enums/analyticEventEnum'
import { Maybe, SkillStepType, VettedSkill } from 'graphql/types'
import { useAnalytic } from 'utils/analytic/useAnalytic'

import s from './ResumeContainer.module.scss'

interface IResumeContainerChildrenProps {
  handleVettedSkillsHeaderClick: () => void
  handleVettedSkillsResultClick: (stepType: SkillStepType) => void
}

export interface IResumeContainerProps {
  VettedSkills?: Maybe<VettedSkill[]> | undefined
  resumeId: string

  children: (props: IResumeContainerChildrenProps) => React.ReactNode
}

export const ResumeContainer: React.FC<IResumeContainerProps> = ({
  children,
  VettedSkills,
  resumeId,
}) => {
  const { analytic } = useAnalytic()

  useEffect(() => {
    if (typeof VettedSkills !== 'undefined') {
      const vettedSkillNames = VettedSkills?.map((item) => item.name)

      analytic.event(EnumAnalyticCandidateEvent.VISIT_SHARED_RESUME, {
        'developer id': resumeId,
        'developer skills': vettedSkillNames || [],
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VettedSkills, resumeId])

  const handleVettedSkillsHeaderClick = () => {
    analytic.event(EnumAnalyticCompanyEvent.VETTED_SKILLS_CLICK)
  }

  const handleVettedSkillsResultClick = (stepType: SkillStepType) => {
    analytic.event(
      stepType === SkillStepType.Quiz
        ? EnumAnalyticCompanyEvent.QUIZ_RESULT_CLICK
        : EnumAnalyticCompanyEvent.CODING_RESULT_CLICK
    )
  }

  return (
    <main className={s.ResumeContainer}>
      <div className={s.ResumeContainer__container}>
        {children({
          handleVettedSkillsHeaderClick,
          handleVettedSkillsResultClick,
        })}
      </div>
    </main>
  )
}
