import React from 'react'

import {
  EnumTypographyVariants,
  Typography,
  Button,
  EnumButtonVariants,
} from '@insquad/tools'

import { ReactComponent as StarBigIcon } from 'assets/icons/simple/starBigIcon.svg'
import { CardAnimation } from 'common/animations/CardAnimation/CardAnimation'
import { EnumAnalyticCompanyEvent } from 'common/enums/analyticEventEnum'
import { ShortResume } from 'graphql/types'
import { useAnalytic } from 'utils/analytic/useAnalytic'

import { CandidateCardBadgesList } from './_components/CandidateCardBadgesList/CandidateCardBadgesList'
import { CandidateCardHeader } from './_components/CandidateCardHeader/CandidateCardHeader'
import { CandidateCardInfo } from './_components/CandidateCardInfo/CandidateCardInfo'

import s from './CompanyCandidateCard.module.scss'

export interface ICompanyCandidateCardProps extends ShortResume {
  selectedLanguagesIds: string[]
  selectedTechnologiesIds: string[]
  id: string
  isBlured: boolean
  isPerfectMatch: boolean
  availability?: string

  onSelectResume: (id: string) => void
}

export const CompanyCandidateCard: React.FC<ICompanyCandidateCardProps> = ({
  id,
  firstName,
  summary,
  specialization,
  photo,
  totalExperience,
  overlap,
  Languages,
  Technologies,
  selectedLanguagesIds,
  selectedTechnologiesIds,
  isBlured,
  isPerfectMatch,
  availability,
  onSelectResume,
}) => {
  const { analytic } = useAnalytic()

  const handleOpenProfileClick = () => {
    analytic.event(EnumAnalyticCompanyEvent.VIEW_PROFILE_CLICK, {
      resumeId: id,
    })

    onSelectResume(id)
  }

  return (
    <CardAnimation
      rootProps={{
        onClick: handleOpenProfileClick,
        className: s.CompanyCandidateCard,
      }}
      isVisible
    >
      {isPerfectMatch && (
        <Typography
          className={s.CompanyCandidateCard__badge}
          variant={EnumTypographyVariants.CAPTION}
        >
          <div className={s.CompanyCandidateCard__badgeIcon}>
            <StarBigIcon />
          </div>
          Perfect match
        </Typography>
      )}

      <CandidateCardHeader
        name={firstName || ''}
        role={specialization}
        avatarSrc={photo}
        isBlured={isBlured}
      />

      <CandidateCardInfo
        summary={summary}
        totalExperience={totalExperience}
        overlap={overlap}
        availability={availability}
      />

      {!!Languages?.length && (
        <CandidateCardBadgesList
          title="Languages"
          marginTop="md"
          badges={Languages}
          highlightedBadgesIds={selectedLanguagesIds}
        />
      )}

      {!!Technologies?.length && (
        <CandidateCardBadgesList
          title="Technologies"
          marginTop="sm"
          badges={Technologies}
          highlightedBadgesIds={selectedTechnologiesIds}
        />
      )}

      <div className={s.CompanyCandidateCard__btnWrapper}>
        <Button
          variant={EnumButtonVariants.SECONDARY}
          className={s.CompanyCandidateCard__btn}
          onClick={handleOpenProfileClick}
        >
          View profile
        </Button>
      </div>
    </CardAnimation>
  )
}
