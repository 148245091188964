import { gql } from '@apollo/client'

import { FRAGMENT_INTERVIEWER_FULL } from './interviewerFragment'

export const FRAGMENT_INTERVIEW_SESSION = gql`
  fragment FragmentInterviewSession on InterviewSession {
    id
    cancelUrl
    rescheduleUrl
    joinUrl
    scheduledAt
    createdAt
    updatedAt
    Interviewer {
      ...FragmentInterviewerFull
    }
  }

  ${FRAGMENT_INTERVIEWER_FULL}
`
