import React from 'react'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { ReactComponent as CheckIcon } from 'assets/icons/simple/checkIcon.svg'
import { ReactComponent as ClockIcon } from 'assets/icons/simple/clockIcon.svg'
import { ReactComponent as LocationIcon } from 'assets/icons/simple/locationIcon.svg'
import { Maybe } from 'graphql/types'

import s from './CandidateCardInfo.module.scss'

export interface ICandidateCardInfoProps {
  totalExperience?: Maybe<string>
  summary?: Maybe<string>
  overlap?: Maybe<string>
  availability?: Maybe<string>
}

export const CandidateCardInfo: React.FC<ICandidateCardInfoProps> = ({
  totalExperience,
  summary,
  overlap,
  availability,
}) => {
  const [location, timezoneOverlap] = overlap?.split('.') || []

  return (
    <>
      <ul className={s.CandidateCardInfo__list}>
        {totalExperience && (
          <CardInfoListItem text={totalExperience} icon={<ClockIcon />} />
        )}
        {availability && (
          <CardInfoListItem text={availability} icon={<CheckIcon />} />
        )}
        {location && (
          <CardInfoListItem text={`${location}.`} icon={<LocationIcon />} />
        )}
        {timezoneOverlap && <CardInfoListItem text={`${timezoneOverlap}`} />}
      </ul>

      {summary && (
        <Typography
          variant={EnumTypographyVariants.P3}
          className={s.CandidateCardInfo__summary}
          text={summary}
        />
      )}
    </>
  )
}

interface ICardInfoListItemProps {
  text: string
  icon?: JSX.Element
}

const CardInfoListItem: React.FC<ICardInfoListItemProps> = ({ icon, text }) => (
  <Typography
    variant={EnumTypographyVariants.P2}
    className={s.CandidateCardInfo__listItem}
    as="li"
  >
    <div className={s.CandidateCardInfo__listItemIcon}>{icon}</div>
    {text}
  </Typography>
)
