import { gql } from '@apollo/client'

import { FRAGMENT_INTERVIEWER_FULL } from './interviewerFragment'
import { FRAGMENT_SELF_INTERVIEW_SESSION } from './selfInterviewSessionFragment'

export const FRAGMENT_CANDIDATE_FULL = gql`
  fragment FragmentCandidateFull on Candidate {
    id
    status
    interviewBookUrl
    uploadedCv
    isReadyForInterview
    locale
    noticePeriod
    moderationComment
    experience

    CandidateSkills {
      skillName
      publicName
      icon
      CandidateSkillSteps {
        skillName
        stepNumber
        stepType
        versionNumber
        scorePercent
        requireScore
        retryAfter
        passed
        finishedAt
        url
        sessionSkillGuid
        blocked
      }
    }

    InterviewSession {
      joinUrl
      rescheduleUrl
      scheduledAt
      Interviewer {
        ...FragmentInterviewerFull
      }
    }

    SelfInterviewSession {
      ...FragmentSelfInterviewSession
    }
  }

  ${FRAGMENT_SELF_INTERVIEW_SESSION}
  ${FRAGMENT_INTERVIEWER_FULL}
`
