import React from 'react'

import clsx from 'clsx'

import s from './HeaderContainer.module.scss'

export interface IHeaderContainerProps {
  children: React.ReactNode

  className?: string
  innerClassName?: string
}

export const HeaderContainer: React.FC<IHeaderContainerProps> = ({
  children,
  className,
  innerClassName,
}) => {
  return (
    <header className={clsx(s.HeaderContainer, className)}>
      <div className={clsx(s.HeaderContainer__inner, innerClassName)}>
        {children}
      </div>
    </header>
  )
}
