import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { GraphQLError } from 'graphql'

import { useToggle } from '@insquad/tools'

import { CandidateResume } from 'common/components/CandidateResume'
import { EnumCompanyQueries } from 'common/enums/companyQueriesEnum'
import {
  EnumCompanyRoutes,
  EnumCandidateRoutes,
} from 'common/enums/routesEnums'
import { FirstLoadingLayout } from 'common/layouts/FirstLoadingLayout/FirstLoadingLayout'
import { NotFoundLayout } from 'common/layouts/NotFoundLayout/NotFoundLayout'
import { useGetLoginQuery } from 'graphql/queries/login/hooks/useGetLoginQuery'
import { useResumeQuery } from 'graphql/queries/resume/hooks/useResumeQuery'
import { UserType } from 'graphql/types'
// import { ROUTE_QUERY_RESUME_ID } from 'routes/private/company/store/useCompanySelectResume'

import { ResumeContainer } from './ResumeContainer/ResumeContainer'
import { ResumeHireBanner } from './ResumeHireBanner/ResumeHireBanner'
import { ResumeLoginBanner } from './ResumeLoginBanner/ResumeLoginBanner'

import s from './CandidateResumePage.module.scss'

export interface ICandidateResumePageProps {}

export const CandidateResumePage: React.FC<ICandidateResumePageProps> = () => {
  const navigate = useNavigate()
  const { id = '' } = useParams()
  const location = useLocation()
  const isOpenResume = location.search.split(/[\?,\&]/g).includes('open=true')
  const notFoundToggler = useToggle()

  const { loading: isLoadingLogin } = useGetLoginQuery({
    onCompleted({ result }) {
      if (result.userType === UserType.Candidate) {
        navigate(EnumCandidateRoutes.CANDIDATE_DASHBOARD_ROUTE)
      }

      if (result.userType === UserType.Company) {
        navigate(
          EnumCompanyRoutes.COMPANY_CANDIDATES_ROUTE +
            `?${EnumCompanyQueries.ROUTE_QUERY_RESUME_ID}=${id}`
        )
      }
    },
  })

  const {
    data: resumeData,
    loading,
    error,
  } = useResumeQuery({
    variables: { id },
    onError({ graphQLErrors }) {
      const isNotFound = (
        graphQLErrors as (GraphQLError & { statusCode: number })[]
      ).find(({ statusCode }) => statusCode === 404)

      isNotFound && notFoundToggler.set()
    },
  })

  if (notFoundToggler.value) {
    return <NotFoundLayout />
  }

  if (loading || !resumeData || error || isLoadingLogin) {
    return <FirstLoadingLayout />
  }

  const getsectionVettedSkillsProps = (isOpenResume: boolean) => {
    if (!isOpenResume) {
      return {
        areCodingResultsHidden: true,
        hiddenResultsTooltipContent:
          'All functions will be available after the demo call',
      }
    }
  }

  const getInfoProps = (isOpenResume: boolean) => {
    if (!isOpenResume) {
      return {
        isSalaryHidden: true,
        salaryClassName: s.CandidateResumePage__blur,
        imgClassName: s.CandidateResumePage__blur,
        nameClassName: s.CandidateResumePage__blur,
      }
    }
  }

  const getVideoProps = (isOpenResume: boolean) => {
    if (!isOpenResume) {
      return {
        videoClassName: s.CandidateResumePage__blur,
        isVideoViewDisabled: true,
      }
    }
  }

  return (
    <ResumeContainer
      resumeId={id}
      VettedSkills={resumeData.result.VettedSkills}
    >
      {({ handleVettedSkillsHeaderClick, handleVettedSkillsResultClick }) => (
        <>
          <ResumeHireBanner resumeId={resumeData.result.id} />

          <CandidateResume
            resume={resumeData.result}
            sectionsProps={{
              sectionVettedSkillsProps: {
                onCardHeaderClick: handleVettedSkillsHeaderClick,
                onCardWatchResultClick: handleVettedSkillsResultClick,
                ...getsectionVettedSkillsProps(isOpenResume),
              },
              sectionInfoProps: { ...getInfoProps(isOpenResume) },
              sectionVideoProps: {
                ...getVideoProps(isOpenResume),
              },
            }}
          />

          <ResumeLoginBanner resumeId={resumeData.result.id} />
        </>
      )}
    </ResumeContainer>
  )
}
