import React from 'react'

import { Resume } from 'graphql/types'

import { CandidateProjects } from './_sections/CandidateProjects/CandidateProjects'
import {
  CandidateResumeEducation,
  ICandidateResumeEducationProps,
} from './_sections/CandidateResumeEducation/CandidateResumeEducation'
import {
  CandidateResumeInfoFull,
  ICandidateResumeInfoFullProps,
} from './_sections/CandidateResumeInfoFull/CandidateResumeInfoFull'
import {
  CandidateResumeOtherExperience,
  ICandidateResumeOtherExperienceProps,
} from './_sections/CandidateResumeOtherExperience/CandidateResumeOtherExperience'
import {
  CandidateResumeProjectExpectations,
  ICandidateResumeProjectExpectationsProps,
} from './_sections/CandidateResumeProjectExpectations/CandidateResumeProjectExpectations'
import {
  CandidateResumeSkills,
  ICandidateResumeSkillsProps,
} from './_sections/CandidateResumeSkills/CandidateResumeSkills'
import {
  CandidateResumeTechnologies,
  ICandidateResumeTechnologiesProps,
} from './_sections/CandidateResumeTechnologies/CandidateResumeTechnologies'
import {
  CandidateResumeVettedSkills,
  ICandidateResumeVettedSkillsProps,
} from './_sections/CandidateResumeVettedSkills/CandidateResumeVettedSkills'
import {
  CandidateResumeVideo,
  ICandidateResumeVideoProps,
} from './_sections/CandidateResumeVideo/CandidateResumeVideo'
import {
  CandidateResumeWorkExperience,
  ICandidateResumeWorkExperienceProps,
} from './_sections/CandidateResumeWorkExperience/CandidateResumeWorkExperience'

export interface ICandidateResumeProps {
  resume: Resume
  sectionsProps?: {
    sectionEducationProps?: Partial<ICandidateResumeEducationProps>
    sectionInfoProps?: Partial<ICandidateResumeInfoFullProps>
    sectionProjectExpectationsProps?: Partial<ICandidateResumeProjectExpectationsProps>
    sectionSkillsProps?: Partial<ICandidateResumeSkillsProps>
    sectionTechnologiesProps?: Partial<ICandidateResumeTechnologiesProps>
    sectionOtherExperience?: Partial<ICandidateResumeOtherExperienceProps>
    sectionVettedSkillsProps?: Partial<ICandidateResumeVettedSkillsProps>
    sectionVideoProps?: Partial<ICandidateResumeVideoProps>
    sectionWorkExperienceProps?: Partial<ICandidateResumeWorkExperienceProps>
    sectionProjects?: Partial<ICandidateResumeWorkExperienceProps>
  }
}

export const CandidateResume: React.FC<ICandidateResumeProps> = ({
  resume,
  sectionsProps,
}) => {
  return (
    <>
      <CandidateResumeInfoFull
        firstName={resume.firstName}
        lastName={resume.lastName}
        photo={resume.photo}
        specialization={resume.specialization}
        experience={resume.totalExperience}
        overlap={resume.overlap}
        price={resume.price}
        englishLevel={resume.englishLevel}
        githubLink={resume.githubLink}
        {...sectionsProps?.sectionInfoProps}
      />

      <CandidateResumeTechnologies
        languages={resume.Languages}
        vettedSkills={resume.VettedSkills}
        technologies={resume.Technologies}
        {...sectionsProps?.sectionTechnologiesProps}
      />

      <CandidateResumeOtherExperience
        technologies={resume.Technologies}
        languages={resume.Languages}
        work={resume.WorkHistory}
        vettedSkills={resume.VettedSkills}
        {...sectionsProps?.sectionOtherExperience}
      />

      <CandidateResumeVideo
        firstName={resume.firstName}
        videoLink={resume.videoLink}
        photo={resume.photo}
        interviewer={resume.Interviewer}
        timecodes={resume.Timecodes}
        {...sectionsProps?.sectionVideoProps}
      />

      <CandidateResumeVettedSkills
        vettedSkills={resume.VettedSkills}
        isTitleHidden
        {...sectionsProps?.sectionVettedSkillsProps}
      />

      <CandidateResumeSkills
        text={resume.keySkills}
        {...sectionsProps?.sectionSkillsProps}
      />

      <CandidateResumeProjectExpectations
        text={resume.projectExpectations}
        {...sectionsProps?.sectionProjectExpectationsProps}
      />

      <CandidateResumeWorkExperience
        workExperience={resume.WorkHistory}
        {...sectionsProps?.sectionWorkExperienceProps}
      />

      <CandidateProjects
        projects={resume.Projects}
        {...sectionsProps?.sectionProjects}
      />

      <CandidateResumeEducation
        education={resume.Education}
        {...sectionsProps?.sectionEducationProps}
      />
    </>
  )
}
