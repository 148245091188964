import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  EnumTypographyVariants,
  Typography,
  Button,
  EnumButtonVariants,
  EnumBreakpoints,
  useMediaQuery,
} from '@insquad/tools'

import { BannerAnimation } from 'common/animations/BannerAnimation/BannerAnimation'
import { Logo } from 'common/components/Logo/Logo'
import { UserType } from 'graphql/types'

import { ReactComponent as LoginBannerIcon } from './loginBannerIcon.svg'

import s from './ResumeLoginBanner.module.scss'

export interface IResumeLoginBannerProps {}

export const ResumeLoginBanner: React.FC<IResumeLoginBannerProps> = () => {
  const { loginWithRedirect } = useAuth0()

  const handleSignUp = (userType: UserType) => {
    return () => {
      loginWithRedirect({
        redirectUri: window.origin + `?role=${userType.toLocaleLowerCase()}`,
      })
    }
  }

  const isMobile = useMediaQuery(EnumBreakpoints.BREAKPOINT_MD, 'max')

  const icon = (
    <div className={s.ResumeLoginBanner__icon}>
      <LoginBannerIcon />
    </div>
  )

  return (
    <BannerAnimation
      isVisible
      rootProps={{
        className: s.ResumeLoginBanner,
      }}
    >
      <div className={s.ResumeLoginBanner__content}>
        <div className={s.ResumeLoginBanner__logoWrapper}>
          <Logo isSmall={isMobile} />
        </div>

        {isMobile && icon}

        <Typography
          variant={EnumTypographyVariants.H1}
          className={s.ResumeLoginBanner__title}
          text="Connecting developers from all over the world with their dream opportunities"
        />

        <div className={s.ResumeLoginBanner__btns}>
          <Button
            onClick={handleSignUp(UserType.Candidate)}
            className={s.ResumeLoginBanner__btn}
          >
            Apply for a job
          </Button>

          <Button
            onClick={handleSignUp(UserType.Company)}
            variant={EnumButtonVariants.SECONDARY}
            className={s.ResumeLoginBanner__btn}
          >
            Hire developers
          </Button>
        </div>
      </div>

      {!isMobile && icon}
    </BannerAnimation>
  )
}
