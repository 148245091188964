import { setContext } from '@apollo/client/link/context'

import { getAuth0Token } from 'utils/getAuth0Token'

export const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${getAuth0Token()}`,
    },
  }
})
