import { EnumLocalStorageKeys } from 'common/enums/localStorageEnum'

let alreadyGottenToken = ''

export const getAuth0Token = () => {
  if (alreadyGottenToken) {
    return alreadyGottenToken
  }

  let token = ''
  try {
    Object.keys(localStorage).forEach((key) => {
      if (key.includes(EnumLocalStorageKeys.OAUTH_TOKEN_PART)) {
        const data = JSON.parse(localStorage.getItem(key) as string)
        token = data?.body?.access_token ?? ''
        alreadyGottenToken = token
      }
    })
  } catch {}

  return token
}
