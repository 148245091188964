import React from 'react'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { ReactComponent as KeyIcon } from 'assets/icons/simple/keyIcon.svg'
import { Maybe } from 'graphql/types'

import { getHTMLFromResumeText } from '../_utils'
import { CandidateResumeSection } from '../CandidateResumeSection/CandidateResumeSection'

import s from './CandidateResumeSkills.module.scss'

export interface ICandidateResumeSkillsProps {
  text: Maybe<string> | undefined
}

export const CandidateResumeSkills: React.FC<ICandidateResumeSkillsProps> = ({
  text,
}) => {
  if (!text) {
    return null
  }

  return (
    <CandidateResumeSection icon={<KeyIcon />} title="Soft Skills">
      <Typography
        variant={EnumTypographyVariants.P2}
        className={s.KeySkillCard__description}
        dangerouslySetInnerHTML={{
          __html: getHTMLFromResumeText(text),
        }}
      />
    </CandidateResumeSection>
  )
}
