import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'

import { FirstLoadingLayout } from 'common/layouts/FirstLoadingLayout/FirstLoadingLayout'
import { useGetCandidateQueryLazy } from 'graphql/queries/candidate/hooks/useCandidateQuery'
import { useCompanyQueryLazy } from 'graphql/queries/company/hooks/useCompanyQuery'
import { useGetLoginLazyQuery } from 'graphql/queries/login/hooks/useGetLoginQuery'
import { UserType } from 'graphql/types'
import { SalesLandingPage } from 'routes/landing/SalesLandingPage/SalesLandingPage'

export const PrivateRoutesWrapper = () => {
  const [searchParams] = useSearchParams()
  const slug = searchParams.get('devs')

  if (!!slug) {
    return <SalesLandingPage slug={slug.replace(/ /g, '+')} />
  }

  return <PrivateRoutes />
}

export const PrivateRoutes: React.FC = withAuthenticationRequired(() => {
  const { isAuthenticated } = useAuth0()
  const [LazyPage, setLazyPage] =
    useState<React.LazyExoticComponent<React.FC> | null>(null)

  const [getCandidate] = useGetCandidateQueryLazy({
    onCompleted() {
      import('./candidate').then(({ CandidatePageLazy }) => {
        setLazyPage(CandidatePageLazy)
      })
    },
  })
  const [getCompany] = useCompanyQueryLazy({
    onCompleted() {
      import('./company').then(({ CompanyPageLazy }) => {
        setLazyPage(CompanyPageLazy)
      })
    },
  })

  const [getLogin] = useGetLoginLazyQuery({
    onCompleted({ result }) {
      if (result.userType === UserType.Candidate) {
        getCandidate()
      }

      if (result.userType === UserType.Company) {
        getCompany()
      }
    },
  })

  useEffect(() => {
    if (isAuthenticated) {
      getLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if (LazyPage) {
    return <LazyPage />
  }

  return <FirstLoadingLayout />
})
