import React from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import s from './CandidateResumeSection.module.scss'

export interface ICandidateResumeSectionProps {
  children?: React.ReactNode
  className?: string
  contentClassName?: string
  isBorderHidden?: boolean

  title: string
  icon: JSX.Element
}

export const CandidateResumeSection: React.FC<ICandidateResumeSectionProps> = ({
  className,
  title,
  icon,
  children,
  isBorderHidden,
  contentClassName,
}) => {
  return (
    <>
      <section className={clsx(s.CandidateResumeSection, className)}>
        <Typography
          variant={EnumTypographyVariants.H3}
          mobileVariant={EnumTypographyVariants.H3}
          className={s.CandidateResumeSection__title}
        >
          <div className={s.CandidateResumeSection__icon}>{icon}</div>

          {title}
        </Typography>

        <div
          className={clsx(s.CandidateResumeSection__content, contentClassName)}
        >
          {children}
        </div>
      </section>
      <div
        className={clsx(s.CandidateResumeSection__border, {
          [s.CandidateResumeSection__content_borderHidden]: isBorderHidden,
        })}
      ></div>
    </>
  )
}
