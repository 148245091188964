import { gql } from '@apollo/client'

import { FRAGMENT_COMPANY_FULL } from 'graphql/fragments/companyFragment'
import { Company } from 'graphql/types'

export interface IGetCompanyResponse {
  result: Company
}

export const QUERY_GET_COMPANY = gql`
  query getCompany {
    result: getCompany {
      ...FragmentCompanyFull
    }
  }

  ${FRAGMENT_COMPANY_FULL}
`
