import { ZustandMiddleware } from 'utils/types'

import { ICompanyStoreSkillsFilters } from './useCompanyStoreSkillsFilters'

export const setFiltersQuery = ({
  domainIds,
  languageIds,
  technologyIds,
}: Pick<
  ICompanyStoreSkillsFilters,
  'domainIds' | 'languageIds' | 'technologyIds'
>) => {
  const setQuery = (name: string, value: string[]) => {
    const url = new URL(window.location.toString())

    if (value.length) {
      url.searchParams.set(name, value.join(',').trim())
    } else {
      url.searchParams.delete(name)
    }

    window.history.pushState(null, '', url.toString())
  }

  setQuery('languages', languageIds)
  setQuery('technologies', technologyIds)
  setQuery('domains', domainIds)
}

export const getFiltersFromQuery = (): Pick<
  ICompanyStoreSkillsFilters,
  'domainIds' | 'languageIds' | 'technologyIds'
> => {
  const { searchParams } = new URL(window.location.toString())

  const domainIds: string[] = searchParams.get('domains')?.split(',') ?? []
  const languageIds: string[] = searchParams.get('languages')?.split(',') ?? []
  const technologyIds: string[] =
    searchParams.get('technologies')?.split(',') ?? []

  return {
    domainIds,
    languageIds,
    technologyIds,
  }
}

export const hasFiltersInQuery = () => {
  const { searchParams } = new URL(window.location.toString())

  return (
    !!searchParams.get('languages') ||
    !!searchParams.get('technologies') ||
    !!searchParams.get('domains')
  )
}

export const _queryStringMiddleware: ZustandMiddleware<
  ICompanyStoreSkillsFilters
> = (config) => (set, get, api) =>
  config(
    (...args) => {
      set(...args)
      setFiltersQuery(get())
    },
    get,
    api,
    []
  )
