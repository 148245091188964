import React, { useCallback, useMemo, useRef, useState } from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
  useEventListener,
  useMount,
} from '@insquad/tools'

import { Maybe } from 'graphql/types'

import { getBadgesToRender, IBadge } from './utils'

import s from './CandidateCardBadgesList.module.scss'

export interface ICandidateCardBadgesListProps {
  title: string
  badges: Maybe<Array<IBadge>>
  highlightedBadgesIds: Array<string>

  marginTop?: 'sm' | 'md'
}

export const CandidateCardBadgesList: React.FC<
  ICandidateCardBadgesListProps
> = ({ title, badges, marginTop, highlightedBadgesIds }) => {
  const [listWidth, setListWidth] = useState<number | null>(null)
  const listRef = useRef<HTMLDivElement | null>(null)

  const highlightedIdsMap = useMemo(
    () =>
      highlightedBadgesIds.reduce(
        (accom, id) => ({ ...accom, [id]: true }),
        {} as Record<string, boolean>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const badgesToRender = useMemo(() => {
    if (!listWidth || !badges) {
      return []
    }

    return getBadgesToRender(badges, listWidth, highlightedIdsMap)
  }, [badges, listWidth, highlightedIdsMap])

  const handleWindowResize = useCallback(() => {
    if (listRef.current) {
      setListWidth(listRef.current.clientWidth)
    }
  }, [])

  useEventListener('resize', handleWindowResize)
  useMount(() => handleWindowResize())

  return (
    <>
      <Typography
        variant={EnumTypographyVariants.P2}
        className={clsx(s.CandidateCardBadgesList__title, {
          [s.CandidateCardBadgesList__title_marginMd]: marginTop === 'md',
          [s.CandidateCardBadgesList__title_marginSm]: marginTop === 'sm',
        })}
        text={title}
      />

      <div ref={listRef} className={s.CandidateCardBadgesList__badges}>
        {badgesToRender.map(({ icon, publicName, id }, i) => {
          return (
            <Typography
              key={i}
              variant={EnumTypographyVariants.P3}
              className={clsx(s.CandidateCardBadgesList__badge, {
                [s.CandidateCardBadgesList__badge_active]:
                  highlightedIdsMap[id],
              })}
              as="div"
            >
              {icon && (
                <div className={s.CandidateCardBadgesList__badgeImg}>
                  <img src={icon} alt="" />
                </div>
              )}

              {publicName}
            </Typography>
          )
        })}
      </div>
    </>
  )
}
