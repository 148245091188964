export enum EnumAnalyticCandidateEvent {
  SIGN_UP_COMPLETE = 'complete sign up',
  SIGN_UP_MODAL = 'sign up modal',

  // Pages
  DASHBOARD_PAGE = 'Dashboard Page',
  SKILLS_PAGE = 'Skills Assessment Page',
  RESUME_PAGE = 'Resume Page',
  HELP_PAGE = 'Help Center Page',
  INVITE_FRIEND = 'Invite friend',

  // Events on Dashboard and Skills Pages
  START_ONBOARDING = 'start onboarding',
  LEARN_DETAILS = 'learn details',
  READ_STORY = 'read story',
  READ_FAQ = 'read faq',
  START_SELF_INTERVIEW = 'start self interview',
  USEFUL_LINK_CLICK = 'useful link click',
  VACANCY_APPLY_CLICK = 'vacancy apply click',
  UPLOAD_CV_FILE_CLICK = 'upload cv file click',
  SEND_CV_LINK_CLICK = 'send cv link click',
  SELECT_SKILL_CLICK = 'select skill click',
  START_QUIZ_CLICK = 'start quiz click',
  START_CODING_CLICK = 'start coding click',
  SCHEDULE_INTERVIEW_CLICK = 'schedule interview click',
  SEE_MY_PROFILE = 'see my profile',
  SEE_SAMPLE_PROFILE = 'see sample profile',
  ASSESS_SKILLS = 'assess skills',
  CHECK_MY_PROGRESS = 'check my progress',
  PASS_ADDITIONAL_SKILLS_CLICK = 'pass additional skills click',
  SHARE_RESUME_DASHBOARD_CLICK = 'share resume dashboard click',
  SHARE_RESUME_DASHBOARD_CV_CLICK = 'share resume dashboard cv click',
  OPEN_RESUME_DASHBOARD_CLICK = 'open resume dashboard click',

  // Events for gtm only
  PASS_QUIZ = 'pass quiz',
  FAIL_QUIZ = 'fail quiz',
  PASS_CODING = 'pass coding',
  FAIL_CODING = 'fail coding',

  VISIT_SHARED_RESUME = 'visit shared resume',

  NOTICE_PERIOD_CLICK = 'notice period click',

  SHARE_RESUME_CV = 'share resume cv',
  SHARE_VETTED_SKILL = 'share vetted skill',
}

export enum EnumAnalyticCompanyEvent {
  SIGN_UP_COMPLETE = 'complete sign up',
  SIGN_UP_MODAL = 'sign up modal',
  VIEW_ALL_SKILLS_CLICK = 'view all skills click',
  VIEW_ALL_DOMAINS_CLICK = 'view all domains click',
  LOAD_MORE_CLICK = 'load more click',
  VIEW_PROFILE_CLICK = 'view profile click',
  VIEW_PROFILE = 'view profile',
  VIDEO_INTERVIEW_CLICK = 'video interview click',
  QUIZ_RESULT_CLICK = 'quiz result click',
  CODING_RESULT_CLICK = 'coding result click',
  VETTED_SKILLS_CLICK = 'vetted skills collapse/expand',
  CONTACT_US_CLICK = 'contact us click',
  BOOK_CALL_CLICK = 'book call click',
  SHARE_PROFILE_CLICK = 'share profile click',
  ASK_QUESTION_CLICK = 'ask question click',
  BOOK_DEMO_INTERVIEW_CLICK = 'book demo interview click',
  BOOKED_DEMO_INTERVIEW = 'booked demo interview',
  SELECT_FILTERS_PRICE_CLICK = 'select filters price click',
}
