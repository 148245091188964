import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
  EnumBreakpoints,
  useMediaQuery,
} from '@insquad/tools'

import { ReactComponent as ChevronIcon } from 'assets/icons/simple/chevronIcon.svg'
import { Maybe } from 'graphql/types'

import { LevelScore } from '../LevelScore/LevelScore'

import { ReactComponent as BadgeIcon } from './badgeIcon.svg'

import s from './CardHeader.module.scss'

export interface ICardHeaderProps {
  icon: Maybe<string> | undefined
  score: number
  title: string
  isOpen: boolean

  onToggle: () => void
}

export const CardHeader: React.FC<ICardHeaderProps> = ({
  icon,
  score,
  title,
  isOpen,
  onToggle,
}) => {
  const isDesktop = useMediaQuery(EnumBreakpoints.BREAKPOINT_LG, 'min')

  const scoreToDisplay = score / 20

  const levelToDisplay = (score: number) => {
    if (score <= 3.3) {
      return {
        text: 'Advanced',
        textStyle: s.CardHeader__levelText_violet,
        boxStyle: s.CardHeader__level_violet,
      }
    }
    if (score <= 4.4) {
      return {
        text: 'Pro',
        textStyle: s.CardHeader__levelText_blue,
        boxStyle: s.CardHeader__level_blue,
      }
    }
    return {
      text: 'Expert',
      textStyle: s.CardHeader__levelText_green,
      boxStyle: s.CardHeader__level_green,
    }
  }

  const level = levelToDisplay(scoreToDisplay)

  const renderTopPercent = () => {
    if (scoreToDisplay < 4) {
      return null
    }

    let percent = 20

    if (scoreToDisplay >= 4.4) {
      percent = 10
    }

    if (scoreToDisplay >= 4.8) {
      percent = 5
    }

    return (
      <Typography
        variant={EnumTypographyVariants.P2}
        text={`Top ${percent}% across all candidates`}
        className={s.CardHeader__topPercent}
      />
    )
  }

  const renderLevelScore = () => (
    <div className={s.CardHeader__level}>
      <Typography
        variant={EnumTypographyVariants.P2}
        className={clsx(s.CardHeader__score, level.textStyle)}
        text="Candidate's level"
      />

      <LevelScore level={level.text} className={level.boxStyle} />

      <Typography
        variant={EnumTypographyVariants.P2}
        className={clsx(s.CardHeader__score, level.textStyle)}
        text={level.text}
      />
    </div>
  )

  return (
    <div className={s.CardHeader__wrapper} onClick={onToggle}>
      <div className={s.CardHeader__badge} id={title}>
        <BadgeIcon />
      </div>

      <div className={s.CardHeader}>
        <Typography
          variant={EnumTypographyVariants.H3}
          className={s.CardHeader__title}
        >
          {!!icon && (
            <div className={s.CardHeader__img}>
              <img src={icon} alt="" />
            </div>
          )}

          {title}
        </Typography>

        <div className={s.CardHeader__starsWrapper}>
          {isDesktop && renderTopPercent()}

          {isDesktop && renderLevelScore()}
          <div className={s.CardHeader__iconWrapper}>
            <ChevronIcon
              className={clsx(s.CardHeader__icon, {
                [s.CardHeader__icon_rotated]: isOpen,
              })}
            />
          </div>
        </div>

        {!isDesktop && (
          <div>
            {renderLevelScore()}
            {renderTopPercent()}
          </div>
        )}
      </div>
    </div>
  )
}
