import React from 'react'

import { ErrorBoundary as SentryErrorBoundary, Scope } from '@sentry/react'

import { NotFoundLayout } from 'common/layouts/NotFoundLayout/NotFoundLayout'

export interface IErrorBoundaryProps {
  children: React.ReactNode
}

export const ErrorBoundary: React.FC<IErrorBoundaryProps> = ({ children }) => {
  const handleClickBtn = () => {
    window.location.replace('/')
  }

  const handleBeforeCapture = (scope: Scope) => {
    scope.setTag('Page', window.location.pathname)
  }

  const fallback = (
    <NotFoundLayout btnText="Reload page" onClickBtn={handleClickBtn} />
  )

  return (
    <SentryErrorBoundary
      beforeCapture={handleBeforeCapture}
      fallback={fallback}
    >
      {children}
    </SentryErrorBoundary>
  )
}
